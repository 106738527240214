import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Slider, { Settings } from 'react-slick';
import styles from './LNEArticlePage.module.scss';
import LNEArticleRelatedProductTile from './LNEArticleRelatedProductTile';
import { ILNEArticleRelatedProductsProps } from './types/ILNEArticleRelatedProductsProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { desktop, tablet } from '../../../../helpers/utils/screensizes';
import sliderStyles from '../LNESlider.module.scss';

const settings: Settings = {
  dots: false,
  arrows: true,
  infinite: true,
  centerMode: false,
  centerPadding: '0px',
  adaptiveHeight: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: desktop,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: tablet,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const LNEArticleRelatedProducts = ({ relatedProducts }: ILNEArticleRelatedProductsProps) => {
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });
  const length = relatedProducts.length;
  const showSlick = length > 1;

  if (length === 0) {
    return false as unknown as JSX.Element;
  } else if (length === 2) {
    settings.slidesToScroll = 2;
    settings.slidesToShow = 2;
  }
  return (
    <Container
      data-testid="lne-article-related-products"
      className={`${styles['lne-article-related-products']} ${sliderStyles['lne-slider']}`}
    >
      <Row>
        <Col>
          <h2>
            {formatMessage({
              id: 'related-products.title',
              defaultMessage: 'This Article Goes Great With These Products',
            })}
          </h2>
        </Col>
      </Row>
      <Row>
        {showSlick ? (
          <Slider {...settings} className={`slides-${length}`}>
            {relatedProducts.map((item, index) => {
              return <LNEArticleRelatedProductTile productKey={item.productKey} key={index} />;
            })}
          </Slider>
        ) : (
          <div className={styles['single-product']}>
            <LNEArticleRelatedProductTile productKey={relatedProducts?.[0]?.productKey} />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default LNEArticleRelatedProducts;
