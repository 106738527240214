import { PageMetaDataDTO } from './CommonFieldsDTO';
import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { LNEArticleBodyDTO } from './LNEArticleBodyDTO';
import { LNEHeroNavDTO } from './LNEHeroNavDTO';
import { LNEPromotionFooterDTO } from './LNEPromotionFooterDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import {
  ILNECollectionArticleReference,
  ILNECollectionArticles,
  ILNECollectionPage,
} from '../interfaces/ILNECollectionPage';
import { ILNEHeroNav } from '../interfaces/ILNEHeroNav';
import { TLNEArticleBody } from '../types/TLNEArticleBody';
import { TLNECollectionArticleData, TLNECollectionPage } from '../types/TLNECollectionPage';

export const LNECollectionArticleReferenceDTO = (data: ILNECollectionArticleReference): TLNEArticleBody | undefined =>
  data?.article_reference?.[0] ? LNEArticleBodyDTO(data?.article_reference?.[0]) : undefined;

export const LNECollectionArticlesDTO = (data: ILNECollectionArticles): TLNECollectionArticleData => ({
  data: data?.article ? LNECollectionArticleReferenceDTO(data?.article) : undefined,
});

export const LNECollectionPageDTO = (data: ILNECollectionPage): TLNECollectionPage => ({
  contentTypeUid: 'lne_collection',
  articles: data?.articles_block ? data?.articles_block.map(LNECollectionArticlesDTO) : [],
  code: data?.code,
  collectionName: data?.collection_name,
  collectionPageDescription: data?.collection_page_description,
  collectionPageTitle: data?.collection_page_title,
  footer: FooterDTO(data.footer_reference[0]),
  header: HeaderDTO(data.header_reference[0]),
  heroNav: LNEHeroNavDTO({ nav_image: data.collection_image } as ILNEHeroNav),
  metaData: PageMetaDataDTO(data.meta_data),
  newUntilDate: data?.new_until_date || null,
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  promotionFooter: LNEPromotionFooterDTO(data.promotion_footer_reference[0]),
  slug: data?.slug,
  uid: data.uid,
});
