import { FC } from 'react';
import { ImageHelpers } from 'helpers/imageHelpers';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { mobile } from '../../../../helpers/utils/screensizes';

export interface PageHeaderProps {
  backgroundImage?: string;
  backgroundImageMobile?: string;
  backgroundImageProps?: string;
  backgroundImagePropsMobile?: string;
  className?: string;
  headerBarClassName?: string;
  pageTitle: string;
  pageTitleClassName?: string;
}

const PageHeader: FC<PageHeaderProps> = ({
  backgroundImage,
  backgroundImageMobile,
  backgroundImageProps,
  backgroundImagePropsMobile,
  className,
  headerBarClassName,
  children,
  pageTitle,
  pageTitleClassName,
}) => {
  const [isBiggerThanMobile] = useMediaQuery(mobile);

  let image = undefined;

  if (isBiggerThanMobile && backgroundImage !== undefined) {
    const imageUrl = ImageHelpers.modifiedContentStackImageUrl(backgroundImage, '2100');
    image = `url('${imageUrl}') ${backgroundImageProps}`;
  }
  if (!isBiggerThanMobile && backgroundImageMobile !== undefined) {
    const imageUrl = ImageHelpers.modifiedContentStackImageUrl(backgroundImageMobile, '640');
    image = `url('${imageUrl}') ${backgroundImagePropsMobile}`;
  }
  if (!isBiggerThanMobile && backgroundImageMobile === undefined && backgroundImage !== undefined) {
    const imageUrl = ImageHelpers.modifiedContentStackImageUrl(backgroundImage, '640');
    image = `url('${imageUrl}') ${backgroundImageProps}`;
  }

  const pageHeaderStyles = {
    background: image,
  };

  return (
    <section className={className}>
      <div className={pageTitleClassName} style={pageHeaderStyles}>
        <h1>{pageTitle}</h1>
      </div>
      {children}
    </section>
  );
};

export default PageHeader;
