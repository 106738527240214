import { FileDTO } from './CommonFieldsDTO';
import { LNEAuthorReferenceDTO } from './LNEAuthorDTO';
import {
  ILNEImageDisplay,
  ILNEImageDisplayBlock,
  ILNEImageDisplayGroup,
  ILNEImageDisplayReference,
} from '../interfaces/ILNEImageDisplay';
import { TLNEImageDisplay, TLNEImageDisplayGroup } from '../types/TLNEImageDisplay';

export const LNEImageDisplayBlockDTO = ({ lne_image_display_block }: ILNEImageDisplayBlock): TLNEImageDisplay =>
  LNEImageDisplayReferenceDTO(lne_image_display_block);

export const LNEImageDisplayReferenceDTO = ({
  lne_image_display_reference,
}: ILNEImageDisplayReference): TLNEImageDisplay => LNEImageDisplayDTO(lne_image_display_reference[0]);

export const LNEImageDisplayGroupDTO = (data: ILNEImageDisplayGroup): TLNEImageDisplayGroup => ({
  authorsGroup: data?.authors_group ? data.authors_group?.map(LNEAuthorReferenceDTO) : [],
  caption: data?.caption,
  desktopImage: FileDTO(data?.desktop_image),
  expirationDate: data?.expiration_date || null,
  imageAlt: data?.image_alt,
  isExpired: data?.expiration_date ? new Date(data?.expiration_date).valueOf() - new Date().valueOf() < 0 : false,
  mobileImage: FileDTO(data?.mobile_image),
});

export const LNEImageDisplayDTO = (data: ILNEImageDisplay): TLNEImageDisplay => ({
  contentTypeUid: 'lne_image_display_block',
  imageDisplayGroup: data?.image_display_group ? data?.image_display_group?.map(LNEImageDisplayGroupDTO) : [],
  lightbox: data?.lightbox,
  uid: data?.uid,
});
