import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { LNEArticleBodyDTO } from './LNEArticleBodyDTO';
import { LNEHeroNavDTO } from './LNEHeroNavDTO';
import { LNEPromotionFooterDTO } from './LNEPromotionFooterDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { ILNECategoryArticleData, ILNECategoryPage } from '../interfaces/ILNECategoryPage';
import { ILNEHeroNav } from '../interfaces/ILNEHeroNav';
import { TLNECategoryArticleData, TLNECategoryPage } from '../types/TLNECategoryPage';

export const LNECategoryArticleDataDTO = (data: ILNECategoryArticleData): TLNECategoryArticleData => ({
  data: data?.data ? LNEArticleBodyDTO(data?.data) : undefined,
});

export const LNECategoryPageDTO = (data: ILNECategoryPage): TLNECategoryPage => ({
  contentTypeUid: 'lne_article_page',
  articles: data?.articles ? data?.articles.map(LNECategoryArticleDataDTO) : [],
  category: data?.category,
  footer: FooterDTO(data.footer_reference[0]),
  header: HeaderDTO(data.header_reference[0]),
  heroNav: LNEHeroNavDTO({ nav_image: data.navigation_banner } as ILNEHeroNav),
  pagination: data?.pagination,
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  promotionFooter: LNEPromotionFooterDTO(data.promotion_footer_reference[0]),
  uid: data.uid,
});
