import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Props } from './interfaces';
import styles from './Tooltip.module.scss';

const Tooltip = ({ availabilityMessage, overlay }: Props) => {
  return (
    <div className={styles['availability-message']}>
      <span data-testid="availability-message">{availabilityMessage}</span>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={
          <Popover id="productItem-tooltip-popoover">
            <Popover.Body id="productItem-tooltip-popoover-body">{overlay}</Popover.Body>
          </Popover>
        }
        delay={150}
      >
        <button>
          <div />
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default Tooltip;
