import React from 'react';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './LNEArticlePage.module.scss';
import { ILNEArticleContributorFooterProps } from './types/ILNEArticleContributorFooterProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import useI18n from '../../../../helpers/hooks/useI18n';
import { getLNEArticleBylineText, getLNEArticleContributorFooterText } from '../utils';

const LNEArticleContributorFooter = ({ creators }: ILNEArticleContributorFooterProps) => {
  const { locale } = useI18n();
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });

  let byline = getLNEArticleBylineText([], creators);
  const creator = creators[0].authorReference?.[0];
  const name = `${creator?.firstName} ${creator?.lastName}`;

  if (creators.length === 1) {
    byline = getLNEArticleContributorFooterText(creators?.[0]);
  }

  return (
    <Container data-testid="lne-article-contributor-footer" className={styles['lne-article-contributor-footer']}>
      <Row className={`g-0 ${styles.contributor}`}>
        {creators.length > 1 ? (
          <Col>
            <div className={styles.byline}>
              <em>
                {formatMessage({
                  id: byline.creatorByline,
                  defaultMessage: 'Featuring',
                })}
              </em>
              <div dangerouslySetInnerHTML={{ __html: byline.creatorString }}></div>
            </div>
          </Col>
        ) : (
          <>
            {Boolean(creator?.personImage?.url) && (
              <Col xs={12} md={4}>
                <div className={styles['contributor-image']}>
                  <img src={creator?.personImage?.url} width={150} height={150} loading="lazy" alt={name} />
                </div>
              </Col>
            )}
            <Col xs={12} md={Boolean(creator?.personImage?.url) ? 8 : 12} className={styles['contributor-info']}>
              <div className={styles.byline}>
                <em>
                  {formatMessage({
                    id: byline.creatorByline,
                    defaultMessage: 'Featuring',
                  })}
                </em>
                <div dangerouslySetInnerHTML={{ __html: byline.creatorString }}></div>
              </div>
              {creator?.isAmbassador && (
                <img
                  src={`/images/lne/${locale}-nikon-ambassador-logo.png`}
                  width={270}
                  height={40}
                  loading="lazy"
                  className={styles['ambassador-logo']}
                  alt={formatMessage({
                    id: 'nikon.ambassador.alt',
                    defaultMessage: 'Nikon Ambassador logo',
                  })}
                />
              )}
              {Boolean(creator?.biography) && <p dangerouslySetInnerHTML={{ __html: creator?.biography }}></p>}
              <Link href={`/learn-and-explore/s/${name}`}>
                <a className={styles.link}>
                  {formatMessage({
                    id: 'contributor.more.link',
                    defaultMessage: 'More articles by this contributor',
                  })}
                  <i className={styles.icon} />
                </a>
              </Link>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default LNEArticleContributorFooter;
