import { useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Col, Container, Row } from 'react-bootstrap';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './ServiceAdvisoryListPage.module.scss';
import { IServiceAdvisoryListPageProps } from './types/IServiceAdvisoryListPageProps';
import { IMAGE_SOURCE } from '../../../helpers/constants/imageSources';
import { DateHelpers } from '../../../helpers/dateHelpers';
import { useFormat } from '../../../helpers/hooks/useFormat';
import { useLocale } from '../../../helpers/hooks/useLocale';
import SkipContent from '../atoms/SkipContent/SkipContent';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const ServiceAdvisoryListPage = ({ data }: IServiceAdvisoryListPageProps) => {
  const router = useRouter();
  const { account, accountLoading } = useAccount();
  const language = useLocale().localizationInfo.language;
  const { formatMessage } = useFormat({ name: 'service-advisory-list' });
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(router.locale);
  const articleTypes = {
    'SERVICE ADVISORY': formatMessage({ id: 'service_advisory', defaultMessage: 'service advisory' }),
    RECALL: formatMessage({ id: 'recall', defaultMessage: 'recall' }),
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading) {
      sendGTMEvent({
        event: 'page_view',
        pageName: PAGE_VIEW_EVENTS.SERVICE_ADVISORY_LIST.NAME,
        pageCategory: PAGE_VIEW_EVENTS.SERVICE_ADVISORY_LIST.CATEGORY,
        pageType: PAGE_VIEW_EVENTS.SERVICE_ADVISORY_LIST.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading]);

  const articleList = data.serviceAdvisoryArticles
    ? data.serviceAdvisoryArticles.sort((article1, article2) => {
        const date1 = article1.articleDateUpdated
          ? new Date(article1.articleDateUpdated)
          : new Date(article1.articleDate);
        const date2 = article2.articleDateUpdated
          ? new Date(article2.articleDateUpdated)
          : new Date(article2.articleDate);
        if (date1 < date2) {
          return 1;
        }
        if (date1 > date2) {
          return -1;
        }
        return 0;
      })
    : [];

  return (
    <div data-testid="service-advisory-list-page" className="service-advisory-list-page">
      <SkipContent />
      {data.promoBanner && <PromoBanner data={data.promoBanner} />}
      {data.header && <Header data={data.header} />}
      <main data-testid="service-advisory-list-page-main-content" id="nkn-main-content" tabIndex={-1}>
        <Container className={styles.service_articles_list}>
          <Row className={styles.page_title}>
            <h1>{formatMessage({ id: 'page_title', defaultMessage: 'Service Advisories' })}</h1>
          </Row>
          <ul>
            {articleList?.map((article) => {
              const articleDate = DateHelpers.intlFormatDate(article.articleDate, 'long', language, 'UTC');

              const articleDateUpdated = article.articleDateUpdated
                ? DateHelpers.intlFormatDate(article.articleDateUpdated, 'long', language, 'UTC')
                : false;
              const articleImage = article.articleImage ? article.articleImage : imageUnavailable;
              const dateBlock =
                articleDateUpdated !== false ? (
                  <div className={styles.date_block}>
                    <p className={styles.updated_date}>
                      <span className={styles.updated_badge}>
                        {formatMessage({ id: 'updated_badge', defaultMessage: 'updated' })}
                      </span>
                      <span>{articleDateUpdated}</span>
                    </p>
                    <p className={styles.original_date}>
                      <span className={styles.original_date_label}>
                        {formatMessage({
                          id: 'original_date_label',
                          defaultMessage: ' original date:',
                        })}
                      </span>
                      {articleDate}
                    </p>
                  </div>
                ) : (
                  <div className={styles.date_block}>
                    <p className={styles.original_date}>{articleDate}</p>
                  </div>
                );
              return (
                <Link href={`/service-advisories/${article.slug}`} key={`article-${article.uid}`}>
                  <li className={styles.article}>
                    <Col xs={9} className={styles.image_wrapper}>
                      <img src={articleImage} alt={`product name`} />
                      <span className={styles.classification_label}>
                        {articleTypes[article.articleClassification as keyof typeof articleTypes]}
                      </span>
                    </Col>
                    <Col xs={11} className={styles['article-info']}>
                      <h3>{article.articleTitle}</h3>
                      {dateBlock}
                    </Col>
                  </li>
                </Link>
              );
            })}
          </ul>
        </Container>
      </main>
      {data.footer && <Footer data={data.footer} />}
    </div>
  );
};

export default ServiceAdvisoryListPage;
