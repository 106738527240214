import React from 'react';
import Head from 'next/head';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { useFormat } from 'helpers/hooks/useFormat';
import { IOverviewTabProps } from './types/IOverviewTabProps';
import ContentBlocks from '../../../ContentBlocks';

const OverviewTab = ({ data, product, fallback }: IOverviewTabProps) => {
  const { formatMessage } = useFormat({ name: 'product' });

  const title = formatMessage({
    id: 'overview.meta.title',
    defaultMessage: 'Nikon {product} | {category} | Nikon USA',
    values: {
      product: product?.name || '',
      category: product.categories?.length ? product.categories[product.categories.length - 1].name || '' : '',
    },
  });

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <Tab.Pane eventKey="overview" className="overview-tab mt-60">
        <ContentBlocks data={data} product={product} />

        {Boolean(fallback) && (
          <Container className={`mt-5 pt-4`}>
            <Row>
              <Col>
                <p>{product?.description}</p>
              </Col>
            </Row>
          </Container>
        )}
      </Tab.Pane>
    </>
  );
};

export default OverviewTab;
