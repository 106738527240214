import { ICartBanner, ICartBannerReference } from '../interfaces/ICartBanner';
import { TCartBanner } from '../types/TCartBanner';

export const CartBannerReferenceDTO = ({ cart_banner_reference }: ICartBannerReference): TCartBanner | undefined => {
  if (!Boolean(cart_banner_reference.length)) {
    return undefined;
  }

  return CartBannerDTO(cart_banner_reference[0]);
};

export const CartBannerDTO = (cartBanner: ICartBanner): TCartBanner => ({
  contentTypeUid: 'cart_banner',
  textCopy: cartBanner.text_copy,
  redirectUrl: cartBanner.redirect_url,
});
