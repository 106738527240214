import React from 'react';
import { ILNEArticleRelatedProductTileProps } from './types/ILNEArticleRelatedProductTileProps';
import useProductByKey from '../../../../helpers/hooks/useProductByKey';
import { TNikonProduct } from '../../../../types/TNikonProduct';
import ProductTile from '../../../commercetools-ui/organisms/product/product-tile';

const LNEArticleRelatedProductTile = ({ productKey }: ILNEArticleRelatedProductTileProps) => {
  const product = useProductByKey(productKey || '');

  if (Boolean(product?.key)) {
    return <ProductTile product={product as unknown as TNikonProduct} />;
  } else {
    return <></>;
  }
};

export default LNEArticleRelatedProductTile;
