import React from 'react';
import { Tab, Container, Row, Col } from 'react-bootstrap';
import { useFormat } from 'helpers/hooks/useFormat';
import { messages } from './messages';
import SupportLinkItem from './SupportLinkItem';
import styles from './SupportTab.module.scss';
import { ISupportTabProps } from './types/ISupportTabProps';

const SupportTab = ({ resources }: ISupportTabProps) => {
  const { formatMessage } = useFormat({ name: 'product' });

  const isManualSectionVisible = resources.some(({ displayDefaultBlock, productManual }) => {
    return displayDefaultBlock && Boolean(productManual?.length);
  });

  return (
    <Tab.Pane eventKey="support" data-testid="support-tab">
      <Container className={styles.supportTab}>
        <h2>{formatMessage(messages.title)}</h2>

        <Row>
          <Col xs={12} sm={8}>
            <section>
              <h3 className={styles.title}>{formatMessage(messages.productRegistration.title)}</h3>
              <div className={styles.content}>
                <p dangerouslySetInnerHTML={{ __html: formatMessage(messages.productRegistration.content) }} />

                <a
                  href={formatMessage(messages.productRegistration.link)}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {formatMessage(messages.productRegistration.linkText)}
                </a>
              </div>
            </section>

            <section>
              <h3 className={styles.title}>{formatMessage(messages.answerSite.title)}</h3>
              <div className={styles.content}>
                <p dangerouslySetInnerHTML={{ __html: formatMessage(messages.answerSite.content) }} />

                <a
                  href={formatMessage(messages.answerSite.link)}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {formatMessage(messages.answerSite.linkText)}
                </a>
              </div>
            </section>
          </Col>

          {Boolean(resources.length) && (
            <Col xs={12} sm={4}>
              {isManualSectionVisible && (
                <section data-testid="support-manual">
                  <h3 className={styles.title}>
                    {formatMessage({ id: 'messages.manual.title', defaultMessage: 'Manual' })}
                  </h3>

                  <div className={styles.content}>
                    {resources.map(({ productManual }) => {
                      return productManual.map(({ manualUrl, manualCopyText, uid }) => (
                        <SupportLinkItem key={uid} href={manualUrl} text={manualCopyText} />
                      ));
                    })}
                  </div>
                </section>
              )}

              <section data-testid="resources">
                <h3 className={styles.title}>{formatMessage(messages.resources.title)}</h3>

                <div className={styles.content}>
                  {resources.map(({ displayDefaultBlock, productResources }) => {
                    if (!displayDefaultBlock || !Boolean(productResources?.length)) {
                      return null;
                    }

                    return productResources.map(({ resourceCopyText, resourceUrl, uid }) => {
                      return <SupportLinkItem key={uid} href={resourceUrl} text={resourceCopyText} />;
                    });
                  })}
                </div>
              </section>
            </Col>
          )}
        </Row>
      </Container>
    </Tab.Pane>
  );
};

export default SupportTab;
