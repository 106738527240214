import React from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import { Nav } from 'react-bootstrap';
import styles from './SecondLevelNavBlock.module.scss';
import { ISecondLevelNavBlockProps } from './types/ISecondLevelNavBlockProps';
import { extractRedirectURL } from '../utils';

const cx = classNames.bind(styles);

const SecondLevelNavBlock = ({ topLevelNav, topLevelNavIdx, isStaticContentBrow }: ISecondLevelNavBlockProps) => {
  const router = useRouter();
  const activePath = isStaticContentBrow ? router.asPath.split('/content')[1] : router.asPath.slice(2);

  return (
    <ul
      className={styles.browSubNav}
      aria-labelledby={`brow-subnav-${topLevelNavIdx}`}
      id={`brow-subnav-${topLevelNavIdx}`}
      data-bs-popper="static"
      role="tablist"
      data-testid={`brow-subnav-${topLevelNavIdx}`}
    >
      {topLevelNav.secondLevelNavigationBlocks.map((secondLevelNav, idx) => {
        return (
          <li
            key={`secondLevelNav-${secondLevelNav.uid}-${idx}`}
            role="tab"
            className={'item subnav-item'}
            data-testid={`brow-subnav-item-${topLevelNavIdx}-${idx}`}
          >
            <Nav.Link
              href={extractRedirectURL(secondLevelNav.subMenuLink.href)}
              className={cx('item-link', { active: activePath === secondLevelNav.subMenuLink.href })}
              data-testid={`brow-subnav-item-link-${topLevelNavIdx}-${idx}`}
            >
              {secondLevelNav.subMenuLink.title}
            </Nav.Link>
          </li>
        );
      })}
    </ul>
  );
};

export default SecondLevelNavBlock;
