import React from 'react';
import PdfIcon from 'components/icons/pdf';
import styles from './SupportTab.module.scss';

interface ISupportLinkItemProps {
  href: string;
  text: string;
}

const SupportLinkItem = ({ href, text }: ISupportLinkItemProps) => {
  const hrefRoutes = href.split('/');
  const isPdfFile = hrefRoutes[hrefRoutes.length - 1]?.includes('.pdf');

  return (
    <a href={href} target="_blank" rel="noreferrer" className={styles.link} data-testid="support-link">
      {text}
      {isPdfFile && <PdfIcon />}
    </a>
  );
};

export default SupportLinkItem;
