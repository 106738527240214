import React, { FC, useRef } from 'react';
import { DataSourceArray } from 'photoswipe';
import { Gallery, GalleryProps, Item } from 'react-photoswipe-gallery';
import { ImageHelpers } from 'helpers/imageHelpers';
export interface galleryImage {
  productImage: {
    url: string;
  };
}
export interface galleryStyleList {
  anchorStyle?: string;
  imgWrapperStyle?: string;
}
export interface GalleryLightboxProps {
  uid?: string;
  className?: string;
  galleryImages?: [galleryImage];
  galleryStyles?: galleryStyleList;
}

const uiElements: GalleryProps['uiElements'] = [
  {
    name: 'bulletsIndicator',
    order: 9,
    isButton: false,
    appendTo: 'wrapper',
    onInit: (el, pswpInstance) => {
      let prevIndex = -1;
      const thumbnails: HTMLElement[] = [];

      el.style.position = 'absolute';
      el.style.bottom = '20px';
      el.style.left = '10px';
      el.style.right = '0';
      el.style.display = 'grid';
      el.style.gap = '10px';
      el.style.gridTemplateColumns = 'repeat(auto-fit, 40px)';
      el.style.gridTemplateRows = 'repeat(auto-fit, 40px)';
      el.style.justifyContent = 'center';
      el.style.objectFit = 'contain';

      const dataSource = pswpInstance.options.dataSource as DataSourceArray;

      for (let i = 0; i < dataSource.length; i++) {
        const slideData = dataSource[i];

        const thumbnail = document.createElement('div');
        thumbnail.style.transition = 'transform 0.15s ease-in';
        thumbnail.style.opacity = '0.6';
        thumbnail.style.cursor = 'pointer';
        thumbnail.onclick = (e: MouseEvent) => {
          const target = e.target as HTMLImageElement | HTMLDivElement;
          const thumbnailEl =
            target.tagName === 'IMG' ? target.parentElement : (e.target as HTMLImageElement | HTMLDivElement);
          if (thumbnailEl) {
            pswpInstance.goTo(thumbnails.indexOf(thumbnailEl));
          }
        };

        const thumbnailImage = document.createElement('img');
        thumbnailImage.setAttribute('src', slideData.msrc || '');
        thumbnailImage.style.width = '100%';
        thumbnailImage.style.height = '100%';
        thumbnailImage.style.objectFit = 'contain';

        thumbnail.appendChild(thumbnailImage);

        el.appendChild(thumbnail);

        thumbnails.push(thumbnail);
      }

      pswpInstance.on('change', () => {
        if (prevIndex >= 0) {
          const prevThumbnail = thumbnails[prevIndex];
          prevThumbnail.style.opacity = '0.6';
          prevThumbnail.style.cursor = 'pointer';
          prevThumbnail.style.transform = 'scale(1)';
        }

        const currentThumbnail = thumbnails[pswpInstance.currIndex];
        currentThumbnail.style.opacity = '1';
        currentThumbnail.style.cursor = 'unset';
        currentThumbnail.style.transform = 'scale(1.2)';

        prevIndex = pswpInstance.currIndex;
      });
    },
  },
];

const GalleryLightbox: FC<GalleryLightboxProps> = ({ uid, className, galleryImages, galleryStyles }) => {
  const imageRef = useRef<any>([]);
  return (
    <Gallery options={{ bgOpacity: 1, zoom: false }} {...{ uiElements: uiElements }}>
      <div className={className} key={uid}>
        {galleryImages?.map((photo_group, index) => {
          const galleryImageRef = imageRef.current[index];
          // @ts-ignore
          return (
            <>
              <div className="d-none">
                <img
                  ref={galleryImageRef}
                  src={ImageHelpers.modifiedContentStackImageUrl(photo_group?.productImage?.url, '54')}
                  style={{ display: 'none', position: 'absolute', objectFit: 'contain' }}
                />
              </div>
              <Item
                original={photo_group?.productImage?.url}
                thumbnail={photo_group?.productImage?.url}
                width={'640'}
                height={'434'}
                key={`photo-group-${index}`}
              >
                {({ ref, open }) => (
                  <a
                    className={galleryStyles?.anchorStyle}
                    target={'_blank'}
                    onClick={(event) => {
                      event.preventDefault();
                      open(event);
                    }}
                  >
                    <div className={galleryStyles?.imgWrapperStyle}>
                      <img
                        className="img-responsive"
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        src={ImageHelpers.modifiedContentStackImageUrl(photo_group?.productImage?.url, '54')}
                        alt={''}
                        draggable={false}
                      />
                    </div>
                  </a>
                )}
              </Item>
            </>
          );
        })}
      </div>
    </Gallery>
  );
};

export default GalleryLightbox;
