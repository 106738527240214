import { FileDTO } from './CommonFieldsDTO';
import { ILNEAuthor, ILNEAuthorReference } from '../interfaces/ILNEAuthor';
import { TLNEAuthor, TLNEAuthorReference } from '../types/TLNEAuthor';

export const LNEAuthorDTO = (data: ILNEAuthor): TLNEAuthor => ({
  biography: data.biography || '',
  city: data.city || '',
  country: data.country || '',
  email: data.email || '',
  firstName: data.first_name || '',
  isAmbassador: data.is_ambassador || false,
  lastName: data.last_name || '',
  personImage: data?.person_image ? FileDTO(data.person_image) : undefined,
  personUrl: data.person_url || '',
  phoneNumber: data.phone_number || '',
  slug: data.slug || '',
  state: data.state || '',
  title: data.title || '',
  uid: data.uid || '',
});

export const LNEAuthorReferenceDTO = (data: ILNEAuthorReference): TLNEAuthorReference => ({
  authorReference: data?.author_reference ? data.author_reference?.map(LNEAuthorDTO) : [],
});
