import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames/bind';
import { Nav, Navbar } from 'react-bootstrap';
import useTouchDevice from 'helpers/hooks/useTouchDevice';
import styles from './LNECategoryBarAndMenu.module.scss';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import LNESearchForm from '../LNESearchForm/LNESearchForm';

const cx = classNames.bind(styles);

const CLPBrow = () => {
  const router = useRouter();
  const activePath = router.asPath;
  const { isTouchDevice } = useTouchDevice();
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });
  const [showSearchMenu, setShowSearchMenu] = useState(false);

  const toggleSearchMenu = () => {
    setShowSearchMenu(!showSearchMenu);
  };

  return (
    <div className={styles['lne-category-bar-and-menu']}>
      <Navbar expand="md">
        <Navbar.Toggle aria-controls="brow-nav" className={styles.mobileMenuButton}>
          {formatMessage({ id: 'menu', defaultMessage: 'Menu' })}
          <span className={`${styles.browMobileIcon} ${styles['mobile-menu-navdown-icon']}`}></span>
        </Navbar.Toggle>

        <Navbar.Collapse id="brow-nav" className={cx('relative', { isTouchDevice: isTouchDevice })}>
          <div className={`${styles['search-bar-container']} ${showSearchMenu ? 'active' : ''}`}>
            <div className={styles['search-bar']}>
              <LNESearchForm placement="menu" />
            </div>
            <div className={styles.close} onClick={() => toggleSearchMenu()}>
              <span className="sr-only">Close Search</span>
            </div>
          </div>

          <ul role="tablist" className={styles.navList}>
            <li role="presentation">
              <Nav.Link
                href="/learn-and-explore/c/tips-and-techniques"
                className={cx({ active: activePath.startsWith('/learn-and-explore/c/tips-and-techniques') })}
              >
                <span>{formatMessage({ id: 'tips-and-techniques', defaultMessage: 'Tips & Techniques' })}</span>
              </Nav.Link>
            </li>
            <li role="presentation">
              <Nav.Link
                href="/learn-and-explore/c/ideas-and-inspiration"
                className={cx({ active: activePath.startsWith('/learn-and-explore/c/ideas-and-inspiration') })}
              >
                <span>{formatMessage({ id: 'ideas-and-inspiration', defaultMessage: 'Ideas & Inspiration' })}</span>
              </Nav.Link>
            </li>
            <li role="presentation">
              <Nav.Link
                href="/learn-and-explore/c/products-and-innovation"
                className={cx({ active: activePath.startsWith('/learn-and-explore/c/products-and-innovation') })}
              >
                <span>{formatMessage({ id: 'products-and-innovation', defaultMessage: 'Products & Innovation' })}</span>
              </Nav.Link>
            </li>
            <li role="presentation" className={styles.searchDesktop}>
              <Nav.Link onClick={() => toggleSearchMenu()}>
                <span>{formatMessage({ id: 'search-articles', defaultMessage: 'Search Articles' })}</span>
                <MagnifyingGlassIcon className="stroke-secondary-white ms-1 h-18 w-18" />
              </Nav.Link>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
export default CLPBrow;
