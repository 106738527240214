import { ILNEPromotionFooter, ILNEPromotionFooterItemsGroup } from '../interfaces/ILNEPromotionFooter';
import { TLNEPromotionFooter, TLNEPromotionFooterItemsGroup } from '../types/TLNEPromotionFooter';

export const LNEPromotionFooterItemsGroupDTO = (
  data: ILNEPromotionFooterItemsGroup,
): TLNEPromotionFooterItemsGroup => ({
  imageAltText: data.image_alt_text,
  imagePath: data.image_path,
  itemDescription: data.item_description,
  itemTitle: data.item_title,
  linkCopyText: data.link_copy_text,
  linkUrl: data.link_url,
  openInNewWindow: data.open_in_new_window,
});

export const LNEPromotionFooterDTO = (data: ILNEPromotionFooter): TLNEPromotionFooter | undefined => {
  if (!data) {
    return;
  }

  return {
    contentTypeUid: 'lne_promotion_footer',
    headline: data.headline,
    itemsGroup: data?.items_group ? data.items_group.map(LNEPromotionFooterItemsGroupDTO) : [],
    uid: data.uid,
  };
};
