import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { IGlossaryItem, IGlossaryPage } from '../interfaces/IGlossaryPage';
import { TGlossaryItem, TGlossaryPage } from '../types/TGlossaryPage';

export const GlossaryPageDTO = (data: IGlossaryPage): TGlossaryPage => ({
  footer: data?.footer_reference?.[0] ? FooterDTO(data.footer_reference[0]) : undefined,
  header: data?.header_reference?.[0] ? HeaderDTO(data.header_reference[0]) : undefined,
  promoBanner: data?.promo_banner_reference?.[0] ? PromoBannerDTO(data.promo_banner_reference[0]) : undefined,
  glossaryTerms: data?.glossary_terms
    ? data?.glossary_terms.map(
        (glossaryItem: IGlossaryItem): TGlossaryItem => ({
          glossaryTerm: glossaryItem?.term_title,
          glossaryDef: glossaryItem?.term_summary,
          glossaryIcon: glossaryItem?.icon_image?.url,
          uid: glossaryItem?.uid,
        }),
      )
    : undefined,
});
