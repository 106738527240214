import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { TNikonProduct } from 'types/TNikonProduct';
import FullTechSpecItem from './FullTechSpecItem';
import styles from './FullTechSpecs.module.scss';

type Props = { specs: TNikonProduct['specs'] };

const FullTechSpecs: React.FC<Props> = ({ specs }) => {
  if (!specs?.length) return null;

  const defaultActiveKeys = [...Array(specs.length)].map((_, i) => i.toString());

  return (
    <div data-testid="full-tech-specs" className={styles['full-specs']}>
      <div className={styles['full-specs']}>
        <Container>
          <Accordion alwaysOpen defaultActiveKey={defaultActiveKeys} flush className={styles['accordion-container']}>
            {specs?.map((specGroup, index) => {
              return <FullTechSpecItem key={index} eventKey={index.toString()} spec={specGroup} />;
            })}
          </Accordion>
        </Container>
      </div>
    </div>
  );
};
export default FullTechSpecs;
