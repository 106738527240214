import React from 'react';
import { useRouter } from 'next/router';
import { Tab } from 'react-bootstrap';
import AccessoriesTab from './AccessoriesTab/AccessoriesTab';
import CompatibleWithTab from './CompatibleWithTab/CompatibleWithTab';
import OverviewTab from './OverviewTab/OverviewTab';
import ProductArchivedBanner from './ProductArchivedBanner';
import ProductForumTab from './ProductForumTab/ProductForumTab';
import RatingsAndReviewsTab from './RatingsAndReviewsTab';
import SupportTab from './SupportTab';
import TechSpecsTab from './TechSpecsTab';
import { IProductOverviewTabsProps } from './types/IProductOverviewTabsProps';
import { appLocales } from '../../../../helpers/constants/appLocales';
import { getLocalizationInfo } from '../../../../project.config';
import { TLocalizationInfo } from '../../../../types/TLocalizationInfo';

const ProductOverviewTabs = ({ data, product, fallback }: IProductOverviewTabsProps) => {
  // TODO: add conditional statements for each tab based on attribute data
  const router = useRouter();
  const locale: TLocalizationInfo = getLocalizationInfo(router.locale);
  const variant = product.variants[0];
  const isReviewable = Boolean(variant.attributes.isReviewable);
  const isUsaStore = locale.appLocale === appLocales.EN_US;
  const isArchived = Boolean(product.isArchived);

  const techSpecsContentBlock = data.techSpecs?.techSpecsContentBlock;
  const hasTechSpecs =
    (techSpecsContentBlock?.length ?? 0) > 1 ||
    !techSpecsContentBlock?.[0]?.defaultTechSpecs ||
    ((techSpecsContentBlock?.[0]?.defaultTechSpecs?.productSpecs?.length ?? 0) > 0 &&
      techSpecsContentBlock?.[0]?.defaultTechSpecs?.displayDefaultBlock);

  const isTechSpecsVisible = locale.appLocale !== appLocales.PT_BR && hasTechSpecs;

  const isShowRatingsAndReviews =
    data.ratingsReviews.defaultRatingsReviews?.displayDefaultBlock && isUsaStore && !isArchived && isReviewable;

  const isShowProductForum = isUsaStore && !isArchived;

  return (
    <Tab.Content data-testid="product-overview-page-main-content-blocks">
      {isArchived && <ProductArchivedBanner locale={router.locale as string} support={data.support} />}
      <OverviewTab data={data.overviewTab} product={product} fallback={fallback} />
      {isTechSpecsVisible && <TechSpecsTab data={data.techSpecs} product={product} />}
      <AccessoriesTab product={product} />
      {isShowRatingsAndReviews && <RatingsAndReviewsTab product={product} variant={variant} />}
      <CompatibleWithTab product={product} />
      {isShowProductForum && <ProductForumTab />}
      <SupportTab resources={data.support} />
    </Tab.Content>
  );
};

export default ProductOverviewTabs;
