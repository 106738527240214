import React, { useEffect } from 'react';
import Head from 'next/head';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { HomePageSchema } from 'components/SEO//HomePage';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import { IHomepageProps } from './types/IHomepageProps';
import SkipContent from '../atoms/SkipContent/SkipContent';
import ContentBlocks from '../ContentBlocks';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const Homepage = ({ data }: IHomepageProps) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { metaDescription, metaImage, metaKeywords } = data?.metaData || {};
  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading) {
      sendGTMEvent({
        event: 'page_view',
        pageName: PAGE_VIEW_EVENTS.HOME.NAME,
        pageCategory: PAGE_VIEW_EVENTS.HOME.CATEGORY,
        pageType: PAGE_VIEW_EVENTS.HOME.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading]);

  const title =
    data.title ||
    formatMessage({
      id: 'meta.title',
      defaultMessage: 'Nikon | Shop & Explore Cameras, Lenses, and Accessories',
    });

  const description =
    metaDescription ||
    formatMessage({
      id: 'meta.desc',
      defaultMessage:
        'Shop the latest mirrorless & DSLR cameras and NIKKOR lenses from the official Nikon eCommerce site. Explore our innovations and photo equipment!',
    });

  return (
    <>
      <Head>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {metaImage && <meta property="og:image" content={metaImage.url} />}
      </Head>
      <div data-testid="homepage" className="nikon-homepage">
        <SkipContent />
        {data.promoBanner && <PromoBanner data={data.promoBanner} />}
        <Header data={data.header} />
        <main data-testid="homepage-main-content" className="homepage-main-content" id="nkn-main-content" tabIndex={-1}>
          <ContentBlocks data={data.homePageContentBlock} />
        </main>
        {data.seo && <HomePageSchema seoData={data.seo} />}
        <Footer data={data.footer} />
      </div>
    </>
  );
};

export default Homepage;
