import React from 'react';
import Link from 'next/link';
import { Badge, Col, Row } from 'react-bootstrap';
import styles from './LNEArticleBlockList.module.scss';
import { ILNEArticleBlockProps } from './types/ILNEArticleBlockProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { getLNEArticleUrl, getLNECategorySlug } from '../utils';

const LNEArticleBlock = ({ data, index, showFeatured = false }: ILNEArticleBlockProps) => {
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });

  const article = data?.articleFieldsGroup;
  const category = article?.articleCategory || '';
  const url = getLNEArticleUrl(category, data?.slug || '');
  const summary =
    (article?.articleSummary?.length || 0) > 150
      ? `${article?.articleSummary.slice(0, 150)}...`
      : article?.articleSummary;
  const newUntilDate = article?.dateSelectors?.newUntilDate || '';
  const isNew = newUntilDate !== '' && new Date(newUntilDate).getTime() - new Date().getTime() > 0;
  let imageUrl = '';
  let imageAlt = '';
  const title = Boolean(article?.articleDisplayName) ? article?.articleDisplayName : article?.articleTitle;

  const isFeatured = index === 0 && showFeatured;

  if (isFeatured) {
    imageUrl = article?.images?.representativeImage?.url || '';
    imageAlt = article?.images?.representativeImage?.title || '';
  } else {
    imageUrl =
      article?.images?.customRectangleRepresentativeImage?.url ||
      article?.images?.rectangleRepresentativeImage?.url ||
      '';
    imageAlt =
      article?.images?.customRectangleRepresentativeImage?.title ||
      article?.images?.rectangleRepresentativeImage?.title ||
      '';
  }

  return (
    <div
      data-testid="lne-category-page-article-block"
      className={`${styles['article-block']} ${isFeatured ? styles.featured : ''}`}
    >
      <Link href={url}>
        <a data-art-id={data?.code}>
          <Row>
            <Col xs={12} md={isFeatured ? 12 : 5}>
              {imageUrl && (
                <div className={styles.image}>
                  <img src={imageUrl} loading="lazy" alt={imageAlt} />
                  {Boolean(
                    article?.articleClassification && article?.articleClassification?.toLowerCase() !== 'none',
                  ) && (
                    <span className={styles[article?.articleClassification?.toLowerCase() || '']}>
                      {formatMessage({
                        id: article?.articleClassification?.toLowerCase() || '',
                        defaultMessage: article?.articleClassification,
                      })}
                    </span>
                  )}
                </div>
              )}
            </Col>
            <Col xs={12} md={isFeatured ? 12 : 7}>
              <p className={styles.category}>
                {isNew && (
                  <Badge className={styles['new-badge']}>{formatMessage({ id: 'new', defaultMessage: 'New' })}</Badge>
                )}
                <span className={styles['category-text']}>
                  {formatMessage({
                    id: getLNECategorySlug(category),
                    defaultMessage: category,
                  })}
                </span>
              </p>
              {Boolean(title) && <h3>{title}</h3>}
              {Boolean(summary) && <p className={styles.summary}>{summary}</p>}
            </Col>
          </Row>
        </a>
      </Link>
    </div>
  );
};

export default LNEArticleBlock;
