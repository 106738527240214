import { NikonAmbassadorsPageDTO } from 'DTO/NikonAmbassadorsPageDTO';
import { NikonMentorsPageDTO } from 'DTO/NikonMentorsPageDTO';
import { TradeInFormPageDTO } from 'DTO/TradeInFormPageDTO';
import { TradeInLandingPageDTO } from 'DTO/TradeInLandingPageDTO';
import CartBanner from './CartBanner';
import CategoryLandingPage from './CategoryLandingPage';
import ErrorPage from './ErrorPage';
import Footer from './Footer/Footer';
import GlossaryPage from './GlossaryPage';
import Header from './Header';
import Homepage from './Homepage/Homepage.tsx';
import LNEArticlePage from './LearnAndExplore/LNEArticlePage';
import LNECategoryPage from './LearnAndExplore/LNECategoryPage';
import LNECollectionPage from './LearnAndExplore/LNECollectionPage';
import LNEHomepage from './LearnAndExplore/LNEHomepage';
import LNESearchResultsPage from './LearnAndExplore/LNESearchResultsPage';
import NikonAmbassadorsPage from './NikonAmbassadorsPage';
import NikonMentorsPage from './NikonMentorsPage';
import PressRoomArticle from './PressRoomArticle';
import PressRoomListPage from './PressRoomListPage';
import ProductOverviewPage from './ProductOverviewPage/ProductOverviewPage';
import PromoBanner from './PromoBanner';
import ServiceAdvisoryArticle from './ServiceAdvisoryArticle';
import ServiceAdvisoryListPage from './ServiceAdvisoryListPage';
import StaticContentPage from './StaticContentPage';
import TradeInFormPage from './TradeInFormPage';
import TradeInLandingPage from './TradeInLandingPage';
import { CartBannerDTO } from '../../DTO/CartBannerDTO';
import { CategoryLandingPageDTO } from '../../DTO/CategoryLandingPageDTO';
import { ErrorPageDTO } from '../../DTO/ErrorPageDTO';
import { FooterDTO } from '../../DTO/FooterDTO';
import { GlossaryPageDTO } from '../../DTO/GlossaryPageDTO';
import { HeaderDTO } from '../../DTO/HeaderDTO';
import { HomepageDTO } from '../../DTO/HomepageDTO';
import { LNEArticlePageDTO } from '../../DTO/LNEArticlePageDTO';
import { LNECategoryPageDTO } from '../../DTO/LNECategoryPageDTO';
import { LNECollectionPageDTO } from '../../DTO/LNECollectionPageDTO';
import { LNEHomepageDTO } from '../../DTO/LNEHomepageDTO';
import { LNESearchResultsPageDTO } from '../../DTO/LNESearchResultsPageDTO';
import { PressRoomArticleDTO } from '../../DTO/PressRoomArticleDTO';
import { PressRoomListPageDTO } from '../../DTO/PressRoomListPageDTO';
import { ProductOverviewPageDTO } from '../../DTO/ProductOverviewPageDTO';
import { PromoBannerDTO } from '../../DTO/PromoBannerDTO';
import { ServiceAdvisoryArticleDTO } from '../../DTO/ServiceAdvisoryArticleDTO';
import { ServiceAdvisoryListPageDTO } from '../../DTO/ServiceAdvisoryListPageDTO';
import { StaticContentPageDTO } from '../../DTO/StaticContentPageDTO';

// TODO: If you add a new component that can be rendered by frontastic with data from contentstack,
//  you need to add a case statement here with the contentTypeUid that returns the new component
//  you're adding.

const ContentstackRenderer = ({ contentTypeUid, data }) => {
  switch (contentTypeUid) {
    case 'home_page':
      return <Homepage data={HomepageDTO(data)} />;
    case 'category_landing_page':
      return <CategoryLandingPage data={CategoryLandingPageDTO(data)} />;
    case 'trade_in_landing_page':
      return <TradeInLandingPage data={TradeInLandingPageDTO(data)} />;
    case 'trade_in_form_page':
      return <TradeInFormPage data={TradeInFormPageDTO(data)} />;
    case 'nikon_ambassadors_page':
      return <NikonAmbassadorsPage data={NikonAmbassadorsPageDTO(data)} />;
    case 'mentors_page':
      return <NikonMentorsPage data={NikonMentorsPageDTO(data)} />;
    case 'error_page':
      return <ErrorPage data={ErrorPageDTO(data)} />;
    case 'cart_banner':
      return <CartBanner data={CartBannerDTO(data)} />;
    case 'lne_article_page':
      return <LNEArticlePage data={LNEArticlePageDTO(data)} />;
    case 'lne_article_category':
      return <LNECategoryPage data={LNECategoryPageDTO(data)} />;
    case 'lne_collection':
      return <LNECollectionPage data={LNECollectionPageDTO(data)} />;
    case 'lne_homepage':
      return <LNEHomepage data={LNEHomepageDTO(data)} />;
    case 'lne_search_results':
      return <LNESearchResultsPage data={LNESearchResultsPageDTO(data)} />;
    case 'pdp_template':
      return <ProductOverviewPage data={ProductOverviewPageDTO(data)} />;
    case 'press_release_article':
      return <PressRoomArticle data={PressRoomArticleDTO(data)} />;
    case 'press_room_page':
      return <PressRoomListPage data={PressRoomListPageDTO(data)} />;
    case 'service_advisory_article':
      return <ServiceAdvisoryArticle data={ServiceAdvisoryArticleDTO(data)} />;
    case 'service_advisory_page':
      return <ServiceAdvisoryListPage data={ServiceAdvisoryListPageDTO(data)} />;
    case 'static_content_page':
      return <StaticContentPage data={StaticContentPageDTO(data)} />;
    case 'promo_banner':
      return <PromoBanner data={PromoBannerDTO(data)} />;
    case 'header':
      return <Header data={HeaderDTO(data)} />;
    case 'footer':
      return <Footer data={FooterDTO(data)} />;
    case 'lne_glossary':
      return <GlossaryPage data={GlossaryPageDTO(data)} />;
    default:
      return <></>;
  }
};

export default ContentstackRenderer;
