import Head from 'next/head';
import { ILNEArticleSchemaProps } from './types/ILNEArticleSchemaProps';

const LNEArticleSchema = ({ metaData }: ILNEArticleSchemaProps) => (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: metaData.metaTitle || '',
        description: metaData.metaDescription || '',
        keywords: metaData.metaKeyword || '',
      })}
    </script>
  </Head>
);

export default LNEArticleSchema;
