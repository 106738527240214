import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { LNEArticleBodyDTO } from './LNEArticleBodyDTO';
import { LNEHeroNavDTO } from './LNEHeroNavDTO';
import { LNEPromotionFooterDTO } from './LNEPromotionFooterDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { ILNEArticlePage } from '../interfaces/ILNEArticlePage';
import { ILNEHeroNav } from '../interfaces/ILNEHeroNav';
import { TLNEArticlePage } from '../types/TLNEArticlePage';

export const LNEArticlePageDTO = (data: ILNEArticlePage): TLNEArticlePage => ({
  contentTypeUid: 'lne_article_page',
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  header: HeaderDTO(data.header_reference[0]),
  heroNav: LNEHeroNavDTO({ nav_image: data.navigation_banner } as ILNEHeroNav),
  articleBody: LNEArticleBodyDTO(data.article_body),
  promotionFooter: LNEPromotionFooterDTO(data.promotion_footer_reference[0]),
  footer: FooterDTO(data.footer_reference[0]),
  uid: data.uid,
});
