import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './HighlightedTechSpecs.module.scss';
import { SpecField } from '../../../../../../../backend/commerce-commercetools/interfaces/specsMapper/ISpecsMapper';
import { Locales, getBooleanSanitizedValue, getTechSpecIconPath } from '../utils';

type Props = { spec: SpecField };

const HighlightedTechSpecItem: React.FC<Props> = ({ spec }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const locale = (router.locale?.replace('-', '_') || 'en_US') as Locales;

  const icon = useMemo(() => {
    return spec.displayName && getTechSpecIconPath(spec.displayName, locale);
  }, [spec.displayName, locale]);

  return (
    <li className={styles['specs-list-item']}>
      <div className={styles['specs-list-item-icon-container']}>
        {icon && (
          <div className={styles['specs-list-item-icon']}>
            <img src={icon} alt={spec.displayName} width={42} height={36} loading="lazy" />
          </div>
        )}
      </div>

      <div className={styles['specs-list-item-texts-container']}>
        <p className={styles['specs-list-item-title']}>{spec.displayName}</p>
        <div>
          <span className={styles['specs-list-item-text']}>
            <div dangerouslySetInnerHTML={{ __html: getBooleanSanitizedValue(spec.value, formatMessage) }} />
          </span>
        </div>
      </div>
    </li>
  );
};

export default HighlightedTechSpecItem;
