import { FileDTO } from './CommonFieldsDTO';
import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { OneToFourColumnReferenceDTO } from './OneToFourColumnDTO';
import { PressRoomArticleDTO } from './PressRoomArticleDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { IPressRoomListPage } from '../interfaces/IPressRoomListPage';
import { TPressRoomListPage } from '../types/TPressRoomListPage';

export const PressRoomListPageDTO = (data: IPressRoomListPage): TPressRoomListPage => {
  return {
    contentTypeUid: 'press_room_page',
    headerDesktopImage: data?.header_desktop_image ? FileDTO(data.header_desktop_image) : undefined,
    headerMobileImage: data?.header_mobile_image ? FileDTO(data.header_mobile_image) : undefined,
    pressRoomArticles: data?.press_release_article?.map(PressRoomArticleDTO) || [],
    pressRoomArticleTotal: data?.press_release_article_total || 0,
    pressResources:
      data?.press_resources?.map((item) => {
        return OneToFourColumnReferenceDTO(item) || [];
      }) || [],
    footer: data?.footer_reference?.[0] ? FooterDTO(data.footer_reference[0]) : undefined,
    header: data?.header_reference?.[0] ? HeaderDTO(data.header_reference[0]) : undefined,
    promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
    uid: data.uid,
  };
};
