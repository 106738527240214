import React from 'react';
import classNames from 'classnames';
import { Col } from 'react-bootstrap';
import styles from './LNEArticlePage.module.scss';
import { ILNEVideoDisplayProps } from './types/ILNEVideoDisplayProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import VideoIframe from '../../atoms/VideoIframe';

const LNEVideoDisplay = ({ data, numColumns }: ILNEVideoDisplayProps) => {
  const [containerRef, isVisible] = useAnimation();
  const nonExpiredVideos = data?.videoDisplayGroup?.filter((video) => !video.isExpired);
  const numVideos = nonExpiredVideos?.length;

  if (!Boolean(numVideos)) {
    return false as unknown as JSX.Element;
  }

  return (
    <>
      {nonExpiredVideos?.map((video, index) => {
        if (!Boolean(video)) {
          return false as unknown as JSX.Element;
        }

        return (
          <Col
            ref={containerRef as any}
            className={classNames('animate', 'content-block', { 'in-view': isVisible }, styles['lne-video-display'])}
            key={index}
            xs={12}
            md={12 / numColumns}
          >
            <div data-testid="lne-video-display-video" className={styles['video-container']}>
              <VideoIframe
                autoplay={video?.autoplay}
                controls={video?.controls}
                loop={video?.loopVideo}
                seoData={video?.seoData}
                videoLocation={video?.videoLocation}
                videoOrientation={video?.videoOrientation}
                videoRefId={video?.videoRefId}
              />
            </div>
            <div data-testid="lne-video-display-caption" className={styles['lne-caption']}>
              {Boolean(video?.authorsGroup?.length) &&
                video?.authorsGroup?.map((author, authorIndex) => {
                  const authorReference = author?.authorReference?.[0];

                  if (Boolean(authorReference?.firstName) && Boolean(authorReference?.lastName)) {
                    return (
                      <p className={styles.author} key={authorIndex}>
                        &copy;{' '}
                        <span itemProp="creator">
                          {authorReference?.firstName} {authorReference?.lastName}
                        </span>
                      </p>
                    );
                  } else return null;
                })}

              {Boolean(video?.caption) && (
                <div className={styles.caption}>
                  <div
                    className={`${styles['caption-content']} flipboard-remove`}
                    dangerouslySetInnerHTML={{ __html: video?.caption }}
                  ></div>
                </div>
              )}
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default LNEVideoDisplay;
