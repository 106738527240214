import {
  IPressRoomArticle,
  IPressRoomDigitalPhotoGallery,
  IPressRoomDigitalPhotoGroup,
  IPressRoomLinks,
  IPressRoomSidebar,
} from '../interfaces/IPressRoomArticle';
import {
  TPressRoomArticle,
  TPressRoomDigitalPhotoGallery,
  TPressRoomDigitalPhotoGroup,
  TPressRoomLinks,
  TPressRoomSidebar,
} from '../types/TPressRoomArticle';

export const PressRoomDigitalPhotoGroupDTO = (
  photo_group: IPressRoomDigitalPhotoGroup,
): TPressRoomDigitalPhotoGroup => {
  return {
    //@ts-ignore
    productImage: photo_group.product_image,
  };
};

export const PressRoomDigitalPhotoGalleryDTO = (
  photo_gallery: IPressRoomDigitalPhotoGallery,
): TPressRoomDigitalPhotoGallery => {
  return {
    digitalPhotoGroup: photo_gallery?.digital_photo_group
      ? photo_gallery?.digital_photo_group?.map(PressRoomDigitalPhotoGroupDTO)
      : [],
    galleryTitle: photo_gallery?.gallery_title,
    downloadURL: photo_gallery?.gallery_redirect_url,
    slug: photo_gallery?.slug,
    title: photo_gallery?.title,
    uid: photo_gallery?.uid,
  };
};

export const PressRoomLinksDTO = (links: IPressRoomLinks): TPressRoomLinks => {
  return {
    linkUrl: links?.link_url,
    linkCopyText: links?.link_copy_text,
  };
};

export const PressRoomArticleSidebarDTO = (sidebar: IPressRoomSidebar): TPressRoomSidebar => {
  return {
    digitalPhotoGalleryReference: sidebar?.digital_photo_gallery_reference
      ? sidebar?.digital_photo_gallery_reference.map(PressRoomDigitalPhotoGalleryDTO)
      : [],
    linksGroup: sidebar?.links_group ? sidebar?.links_group.map(PressRoomLinksDTO) : [],
  };
};

export const PressRoomArticleDTO = (article: IPressRoomArticle): TPressRoomArticle => {
  const data = article.data;
  return {
    contentTypeUid: 'press_room_page',
    articleContent: data?.article_content,
    articleLocation: data?.article_location,
    articleReleaseDate: data?.article_release_date,
    articleSubtitle: data?.article_subtitle,
    articleTitle: data?.article_title,
    articleTopic: data?.article_topic,
    sidebarContent: data?.sidebar_content ? PressRoomArticleSidebarDTO(data?.sidebar_content) : undefined,
    slug: data?.slug,
    title: data?.title,
    uid: data?.uid,
  };
};
