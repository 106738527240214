import React, { useEffect } from 'react';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import LNEArticleBody from './LNEArticleBody';
import styles from './LNEArticlePage.module.scss';
import { ILNEArticlePageProps } from './types/ILNEArticlePageProps';
import LNEArticleSchema from '../../../SEO/LNEArticleSchema';
import Footer from '../../Footer';
import Header from '../../Header';
import PromoBanner from '../../PromoBanner';
import LNECategoryBarAndMenu from '../LNECategoryBarAndMenu/LNECategoryBarAndMenu';
import LNEHeroNav from '../LNEHeroNav';
import LNEPromotionFooter from '../LNEPromotionFooter';

const LNEArticlePage = ({ data }: ILNEArticlePageProps) => {
  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading && data?.articleBody?.articleFieldsGroup?.articleTitle) {
      sendGTMEvent({
        event: 'page_view',
        pageName: data?.articleBody?.articleFieldsGroup?.articleTitle,
        pageCategory: data?.articleBody?.articleFieldsGroup?.articleCategory,
        pageType: PAGE_VIEW_EVENTS.LE_ARTICLE.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading, data?.articleBody?.articleFieldsGroup?.articleTitle]);

  return (
    <div data-testid="lne-article-page" className="lne-article-page">
      {data.articleBody.metadataFields && <LNEArticleSchema metaData={data.articleBody.metadataFields} />}
      {Boolean(data?.promoBanner) && <PromoBanner data={data.promoBanner} />}
      {Boolean(data?.header) && <Header data={data.header} />}
      {Boolean(data?.heroNav) && <LNEHeroNav data={data.heroNav} showSearch={false} />}
      <LNECategoryBarAndMenu />

      <main
        data-testid="lne-article-page-main-content"
        className={styles['lne-article-page-main-content']}
        id="nkn-main-content"
        tabIndex={-1}
      >
        <LNEArticleBody data={data.articleBody} />
        {Boolean(data?.promotionFooter) && <LNEPromotionFooter data={data.promotionFooter} />}
      </main>

      {Boolean(data?.footer) && <Footer data={data.footer} />}
    </div>
  );
};

export default LNEArticlePage;
