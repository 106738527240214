import React from 'react';
import Link from 'next/link';
import { Alert, Container } from 'react-bootstrap';
import styles from './CartBanner.module.scss';
import { ICartBannerProps } from './types/ICartBannerProps';

const CartBanner = ({ data: { redirectUrl, textCopy } }: ICartBannerProps) => {
  if (!Boolean(textCopy.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <Container data-testid="cart-banner" className={styles.cartBanner}>
      {redirectUrl && (
        <Link href={redirectUrl}>
          <a data-testid={'cart-banner-link'}>
            <Alert variant={'danger'} data-testid={'cart-banner-message'}>
              <p dangerouslySetInnerHTML={{ __html: textCopy }} />
            </Alert>
          </a>
        </Link>
      )}
      {!redirectUrl && (
        <Alert variant={'danger'} data-testid={'cart-banner-message'}>
          <p dangerouslySetInnerHTML={{ __html: textCopy }} />
        </Alert>
      )}
    </Container>
  );
};

export default CartBanner;
