import { ILNEQuoteColumn, ILNEQuoteColumnBlock, ILNEQuoteColumnReference } from '../interfaces/ILNEQuoteColumn';
import { TLNEQuoteColumn } from '../types/TLNEQuoteColumn';

export const LNEQuoteColumnBlockDTO = ({ lne_quote_column_block }: ILNEQuoteColumnBlock): TLNEQuoteColumn =>
  LNEQuoteColumnReferenceDTO(lne_quote_column_block);

export const LNEQuoteColumnReferenceDTO = ({ lne_quote_column_reference }: ILNEQuoteColumnReference): TLNEQuoteColumn =>
  LNEQuoteColumnDTO(lne_quote_column_reference[0]);

export const LNEQuoteColumnDTO = (data: ILNEQuoteColumn): TLNEQuoteColumn => ({
  contentTypeUid: 'lne_quote_column_block',
  quoteContent: data?.quote_content,
  uid: data?.uid,
});
