import { INikonAmbassadorsPage } from 'interfaces/INikonAmbassadorsPage';
import { TNikonAmbassadorsPage } from 'types/TNikonAmbassadorsPage';
import { PageMetaDataDTO } from './CommonFieldsDTO';
import { ContentBlocksDTO } from './ContentBlocksDTO';
import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { PromoBannerDTO } from './PromoBannerDTO';

export const NikonAmbassadorsPageDTO = (data: INikonAmbassadorsPage): TNikonAmbassadorsPage => ({
  contentTypeUid: 'nikon_ambassadors_page',
  contentBlocks: ContentBlocksDTO(data.content_blocks),
  fileName: data?.file_name || undefined,
  footer: data?.footer_reference?.[0] ? FooterDTO(data.footer_reference[0]) : undefined,
  header: data?.header_reference?.[0] ? HeaderDTO(data.header_reference[0]) : undefined,
  metaData: PageMetaDataDTO(data.meta_data),
  promoBanner: data?.promo_banner_reference?.[0] ? PromoBannerDTO(data.promo_banner_reference[0]) : undefined,
  title: data?.title,
  uid: data.uid,
});
