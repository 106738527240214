import classNames from 'classnames';
import { Col } from 'react-bootstrap';
import styles from './LNEArticlePage.module.scss';
import { ILNERichTextColumnProps } from './types/ILNERichTextColumnProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const LNERichTextColumn = ({
  data: { dropCap, greyBackground, richTextContent, uid },
  numColumns,
}: ILNERichTextColumnProps) => {
  const [containerRef, isVisible] = useAnimation();

  if (!Boolean(richTextContent?.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <Col
      data-testid="lne-rich-text-column"
      ref={containerRef as any}
      className={classNames(
        'animate',
        'content-block',
        styles['lne-rich-text-column'],
        { 'in-view': isVisible },
        { 'bg-grey': greyBackground },
      )}
      xs={12}
      md={12 / numColumns}
    >
      <div
        className={`lne-rich-text-content ${dropCap ? 'drop-case' : ''}`}
        key={`${uid}`}
        dangerouslySetInnerHTML={{ __html: richTextContent }}
      />
    </Col>
  );
};

export default LNERichTextColumn;
