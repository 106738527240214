import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { LNEArticleBodyDTO } from './LNEArticleBodyDTO';
import { LNEHeroNavDTO } from './LNEHeroNavDTO';
import { LNEPromotionFooterDTO } from './LNEPromotionFooterDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { ILNECategoryArticleData } from '../interfaces/ILNECategoryPage';
import { ILNEHeroNav } from '../interfaces/ILNEHeroNav';
import { ILNESearchResultsPage } from '../interfaces/ILNESearchResultsPage';
import { TLNECategoryArticleData } from '../types/TLNECategoryPage';
import { TLNESearchResultsPage } from '../types/TLNESearchResultsPage';

export const LNESearchResultsArticleDataDTO = (data: ILNECategoryArticleData): TLNECategoryArticleData => ({
  data: data?.data ? LNEArticleBodyDTO(data?.data) : undefined,
});

export const LNESearchResultsPageDTO = (data: ILNESearchResultsPage): TLNESearchResultsPage => ({
  contentTypeUid: 'lne_search_results',
  articles: data?.articles ? data?.articles.map(LNESearchResultsArticleDataDTO) : [],
  author: data?.author || null,
  footer: FooterDTO(data.footer_reference[0]),
  header: HeaderDTO(data.header_reference[0]),
  heroNav: LNEHeroNavDTO({ nav_image: data.navigation_image } as ILNEHeroNav),
  pagination: data?.pagination,
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  promotionFooter: LNEPromotionFooterDTO(data.promotion_footer_reference[0]),
  searchTerm: data?.search_term,
  uid: data.uid,
});
