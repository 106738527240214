import { FileDTO } from './CommonFieldsDTO';
import { LNEAuthorReferenceDTO } from './LNEAuthorDTO';
import { LNE_CONTENT_BLOCKS_DTO_REFERENCES } from '../helpers/constants/LNEContentBlocksDTOReferences';
import {
  ILNEArticleBody,
  ILNEArticleContentBlocks,
  ILNEArticleFieldsGroup,
  ILNEArticleMetadataFields,
  ILNEArticleRelatedProduct,
  ILNEArticleRowBlock,
  ILNEArticleRowsBlock,
} from '../interfaces/ILNEArticleBody';
import {
  ILNEArticleFieldsGroupDateSelectors,
  ILNEArticleFieldsGroupImages,
} from '../interfaces/ILNEFeaturedArticlesSection';
import {
  TLNEArticleBody,
  TLNEArticleFieldsGroup,
  TLNEArticleFieldsGroupDateSelectors,
  TLNEArticleFieldsGroupImages,
  TLNEArticleMetadataFields,
  TLNEArticleRelatedProduct,
  TLNEArticleRowBlock,
  TLNEArticleRowsBlock,
} from '../types/TLNEArticleBody';

export const LNEArticleContentBlocksDTO = (blocks: ILNEArticleContentBlocks) => {
  return blocks.map((block) => {
    const blockName = Object.keys(block)[0];
    const dto = LNE_CONTENT_BLOCKS_DTO_REFERENCES[blockName];

    if (!dto) {
      console.error('The following l&e content block is not covered --> ', blockName);
    }

    return dto && dto(block);
  });
};

export const LNEArticleRowBlockDTO = (data: ILNEArticleRowBlock): TLNEArticleRowBlock => ({
  rowNickname: data?.row_nickname || '',
  columnBlocks: data?.column_blocks ? LNEArticleContentBlocksDTO(data.column_blocks) : undefined,
});

export const LNEArticleRowsBlockDTO = (data: ILNEArticleRowsBlock): TLNEArticleRowsBlock => ({
  rowBlock: data?.row_block ? LNEArticleRowBlockDTO(data.row_block) : undefined,
});

export const LNEArticleRelatedProductDTO = (data: ILNEArticleRelatedProduct): TLNEArticleRelatedProduct => ({
  productKey: data.product_key || '',
});

export const LNEArticleMetadataFieldsDTO = (data: ILNEArticleMetadataFields): TLNEArticleMetadataFields => ({
  metaDescription: data.meta_description || '',
  metaKeyword: data.meta_keyword || '',
  metaTitle: data.meta_title || '',
});

export const LNEArticleFieldsGroupImagesDTO = (data: ILNEArticleFieldsGroupImages): TLNEArticleFieldsGroupImages => ({
  customRectangleRepresentativeImage: FileDTO(data.custom_rectangle_representative_image),
  rectangleRepresentativeImage: FileDTO(data.rectangle_representative_image),
  representativeImage: FileDTO(data.representative_image),
  squareRepresentativeImage: FileDTO(data.square_representative_image),
});

export const LNEArticleFieldsGroupDateSelectorsDTO = (
  data: ILNEArticleFieldsGroupDateSelectors,
): TLNEArticleFieldsGroupDateSelectors => ({
  dateWritten: data?.date_written || null,
  expirationDate: data?.expiration_date || null,
  newUntilDate: data?.new_until_date || null,
});

export const LNEArticleFieldsGroupDTO = (data: ILNEArticleFieldsGroup): TLNEArticleFieldsGroup => ({
  articleCategory: data?.article_category || '',
  articleClassification: data?.article_classification || '',
  articleDisplayName: data?.article_display_name || '',
  articleSubTitle: data?.article_sub_title || '',
  articleSummary: data?.article_summary || '',
  articleTitle: data?.article_title || '',
  dateSelectors: data?.date_selectors ? LNEArticleFieldsGroupDateSelectorsDTO(data.date_selectors) : undefined,
  images: data?.images ? LNEArticleFieldsGroupImagesDTO(data.images) : undefined,
  isArchived: data?.is_archived || false,
  keywords: data?.keywords || '',
});

export const LNEArticleBodyDTO = (data: ILNEArticleBody): TLNEArticleBody => ({
  articleFieldsGroup: LNEArticleFieldsGroupDTO(data.article_fields_group),
  authorsGroup: data?.authors_group ? data.authors_group?.map(LNEAuthorReferenceDTO) : [],
  code: data?.code || '',
  creatorsGroup: data?.creators_group ? data.creators_group?.map(LNEAuthorReferenceDTO) : [],
  hideAssetContributors: data.hide_asset_contributors,
  metadataFields: data?.metadata_fields ? LNEArticleMetadataFieldsDTO(data.metadata_fields) : undefined,
  relatedProducts: data?.related_products ? data.related_products?.map(LNEArticleRelatedProductDTO) : [],
  rowsBlock: data?.rows_block ? data.rows_block.map(LNEArticleRowsBlockDTO) : [],
  slug: data?.slug || '',
});
