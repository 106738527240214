import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { ServiceAdvisoryArticleDTO } from './ServiceAdvisoryArticleDTO';
import { IServiceAdvisoryListPage } from '../interfaces/IServiceAdvisoryListPage';
import { TServiceAdvisoryListPage } from '../types/TServiceAdvisoryListPage';

export const ServiceAdvisoryListPageDTO = (data: IServiceAdvisoryListPage): TServiceAdvisoryListPage => ({
  contentTypeUid: 'service_advisory_page',
  serviceAdvisoryArticles: data?.service_advisory_articles?.map(ServiceAdvisoryArticleDTO) || [],
  footer: data?.footer_reference?.[0] ? FooterDTO(data.footer_reference[0]) : undefined,
  header: data?.header_reference?.[0] ? HeaderDTO(data.header_reference[0]) : undefined,
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  uid: data.uid,
});
