import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './ServiceAdvisoryArticle.module.scss';
import { IServiceAdvisoryArticleProps } from './types/IServiceAdvisoryArticleProps';
import { DateHelpers } from '../../../helpers/dateHelpers';
import { useFormat } from '../../../helpers/hooks/useFormat';
import { useLocale } from '../../../helpers/hooks/useLocale';

const ServiceAdvisoryArticle = ({ data }: IServiceAdvisoryArticleProps) => {
  const { account, accountLoading } = useAccount();
  const language = useLocale().localizationInfo.language;
  const { formatMessage } = useFormat({ name: 'service-advisory-list' });

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading && data?.articleTitle) {
      sendGTMEvent({
        event: 'page_view',
        pageName: data.articleTitle,
        pageCategory: data.articleClassification,
        pageType: PAGE_VIEW_EVENTS.SERVICE_ADVISORY_ARTICLE.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading, data?.articleTitle]);

  const articleDate = DateHelpers.intlFormatDate(data.articleDate, 'long', language, 'UTC');
  const articleTypes = {
    'SERVICE ADVISORY': formatMessage({ id: 'service_advisory', defaultMessage: 'service advisory' }),
    RECALL: formatMessage({ id: 'recall', defaultMessage: 'recall' }),
  };

  const articleDateUpdated = data.articleDateUpdated
    ? DateHelpers.intlFormatDate(data.articleDateUpdated, 'long', language, 'UTC')
    : false;

  const dateBlock =
    articleDateUpdated !== false ? (
      <div className={styles.date_block}>
        <p className={styles.updated_date}>
          <span className={styles.updated_badge}>
            {formatMessage({ id: 'updated_badge', defaultMessage: 'updated' })}
          </span>
          <span>{articleDateUpdated}</span>
        </p>
        <p className={styles.original_date}>
          <span className={styles.original_date_label}>
            {formatMessage({
              id: 'original_date_label',
              defaultMessage: ' original date:',
            })}
          </span>
          {articleDate}
        </p>
      </div>
    ) : (
      <div className={styles.date_block}>
        <p className={styles.original_date}>{articleDate}</p>
      </div>
    );
  return (
    <div data-testid="service-advisory-article" className={styles.service_advisory_article}>
      <main data-testid="service-advisory-article-main-content">
        <Container>
          <Row>
            <Col xs={12}>
              <p className={styles.classification_label}>
                {articleTypes[data?.articleClassification as keyof typeof articleTypes]}
              </p>
              <h1 className={styles.service_article_title}>{data?.articleTitle}</h1>
              {dateBlock}
              <div dangerouslySetInnerHTML={{ __html: data?.articleContent }}></div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default ServiceAdvisoryArticle;
