import { IStaticContentBrow } from 'interfaces/IStaticContentBrow';
import { TopLevelBrowNavigationBlockDTO } from './CLPBrowDTO';
import { TStaticContentBrow } from 'types/TCLPBrow';

export const StaticContentBrowDTO = (staticContentBrowTab: IStaticContentBrow): TStaticContentBrow => {
  return {
    contentTypeUid: staticContentBrowTab._content_type_uid,
    title: staticContentBrowTab.brow_text,
    menuLink: staticContentBrowTab.menu_link,
    topLevelBrowNavigationBlocks:
      staticContentBrowTab.top_level_brow_navigation_block.map(TopLevelBrowNavigationBlockDTO),
    uid: staticContentBrowTab.uid,
  };
};
