import React from 'react';
import { Container } from 'react-bootstrap';
import LNEArticleBlock from './LNEArticleBlock';
import { ILNEArticleBlockListProps } from './types/ILNEArticleBlockListProps';

const LNEArticleBlockList = ({ data, showFeatured = false }: ILNEArticleBlockListProps) => {
  return (
    <Container>
      {data?.map((article, index) => (
        <LNEArticleBlock index={index} key={index} data={article?.data} showFeatured={showFeatured} />
      ))}
    </Container>
  );
};

export default LNEArticleBlockList;
