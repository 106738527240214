import {
  ILNERichTextColumn,
  ILNERichTextColumnBlock,
  ILNERichTextColumnReference,
} from '../interfaces/ILNERichTextColumn';
import { TLNERichTextColumn } from '../types/TLNERichTextColumn';

export const LNERichTextColumnBlockDTO = ({
  lne_rich_text_column_block,
}: ILNERichTextColumnBlock): TLNERichTextColumn => LNERichTextColumnReferenceDTO(lne_rich_text_column_block);

export const LNERichTextColumnReferenceDTO = ({
  lne_rich_text_column_reference,
}: ILNERichTextColumnReference): TLNERichTextColumn => LNERichTextColumnDTO(lne_rich_text_column_reference[0]);

export const LNERichTextColumnDTO = (data: ILNERichTextColumn): TLNERichTextColumn => ({
  contentTypeUid: 'lne_rich_text_column',
  dropCap: data?.drop_cap,
  greyBackground: data?.grey_background,
  richTextContent: data?.rich_text_content,
  uid: data?.uid,
});
