import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Container } from 'react-bootstrap';
import SkipContent from 'components/contentstack/atoms/SkipContent/SkipContent';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './LNECollectionPage.module.scss';
import LNECollectionPageFilters from './LNECollectionPageFilters';
import { ILNECollectionPageProps } from './types/ILNECollectionPageProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import Footer from '../../Footer';
import Header from '../../Header';
import PromoBanner from '../../PromoBanner';
import LNEArticleBlockList from '../LNEArticleBlockList';
import LNECategoryBarAndMenu from '../LNECategoryBarAndMenu/LNECategoryBarAndMenu';
import LNEHeroNav from '../LNEHeroNav';
import LNEPromotionFooter from '../LNEPromotionFooter';
import { getLNECategorySlug } from '../utils';

const LNECollectionPage = ({ data }: ILNECollectionPageProps) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });
  const { account, accountLoading } = useAccount();

  // if there are filters in the url, update the checkboxes
  const initialCategoryString = (router.query.category as string) || '';
  const initialCategoryArray = initialCategoryString?.split(',');
  const [isTipsAndTechniques, setIsTipsAndTechniques] = useState(
    initialCategoryArray.indexOf('tips-and-techniques') >= 0,
  );
  const [isIdeasAndInspiration, setIsIdeasAndInspiration] = useState(
    initialCategoryArray.indexOf('ideas-and-inspiration') >= 0,
  );
  const [isProductsAndInnovation, setIsProductsAndInnovation] = useState(
    initialCategoryArray.indexOf('products-and-innovation') >= 0,
  );
  const [showAll, setShowAll] = useState(!Boolean(router?.query?.category));

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading && data?.collectionName) {
      sendGTMEvent({
        event: 'page_view',
        pageName: data.collectionName,
        pageCategory: PAGE_VIEW_EVENTS.LE_COLLECTION.CATEGORY,
        pageType: PAGE_VIEW_EVENTS.LE_COLLECTION.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading, data?.collectionName]);

  // filter articles before sending to render
  const filteredArticles = data?.articles?.filter((item) =>
    initialCategoryArray.includes(getLNECategorySlug(item?.data?.articleFieldsGroup?.articleCategory || '')),
  );

  const metaTitle = data?.metaData?.metaTitle ? data?.metaData?.metaTitle : data?.collectionPageTitle;
  const metaDescription = data?.metaData?.metaDescription
    ? data?.metaData?.metaDescription
    : data?.collectionPageDescription;
  const metaOGImage = data?.heroNav?.navImage?.url ? data?.heroNav?.navImage?.url : '';

  return (
    <div data-testid="lne-collection-page" className={styles['lne-collection-page']}>
      <SkipContent />
      <Head>
        {Boolean(metaTitle) && <title>{metaTitle}</title>}
        {Boolean(metaDescription) && <meta name="description" content={metaDescription} />}
        {Boolean(metaOGImage) && <meta property="og:image" content={metaOGImage} />}
      </Head>

      {Boolean(data?.promoBanner) && <PromoBanner data={data.promoBanner} />}
      {Boolean(data?.header) && <Header data={data.header} />}
      {Boolean(data?.heroNav) && <LNEHeroNav data={data.heroNav} showSearch={false} />}
      <LNECategoryBarAndMenu />

      <main
        data-testid="lne-collection-page-main-content"
        className={styles['lne-collection-page-main-content']}
        id="nkn-main-content"
        tabIndex={-1}
      >
        <Container>
          <h1>
            <span>{formatMessage({ id: 'collection', defaultMessage: 'Collection:' })}</span>
            {data?.collectionName}
          </h1>
          <div className={styles['lne-filter-pagination-container']}>
            <LNECollectionPageFilters
              isTipsAndTechniques={isTipsAndTechniques}
              setIsTipsAndTechniques={setIsTipsAndTechniques}
              isIdeasAndInspiration={isIdeasAndInspiration}
              setIsIdeasAndInspiration={setIsIdeasAndInspiration}
              isProductsAndInnovation={isProductsAndInnovation}
              setIsProductsAndInnovation={setIsProductsAndInnovation}
              setShowAll={setShowAll}
            />
          </div>
        </Container>
        {Boolean(data?.articles) && (
          <LNEArticleBlockList data={showAll ? data?.articles : filteredArticles} showFeatured={false} />
        )}
        {Boolean(data?.promotionFooter) && <LNEPromotionFooter data={data.promotionFooter} />}
      </main>

      {Boolean(data?.footer) && <Footer data={data.footer} />}
    </div>
  );
};

export default LNECollectionPage;
