import { Col, Container, Row, Tab } from 'react-bootstrap';
import styles from './ProductForumTab.module.scss';
import { useFormat } from '../../../../../helpers/hooks/useFormat';

const ProductForumTab = () => {
  const { formatMessage } = useFormat({ name: 'product' });

  return (
    <Tab.Pane eventKey="product-forum">
      <Container data-testid={`nkn-product-f-container`}>
        <Row>
          <Col className={`${styles['product-forum-tab-container']}`}>
            <h2 data-testid={`nkn-product-f-heading`}>
              {formatMessage({ id: 'forum.title', defaultMessage: 'Product Forum' })}
            </h2>
            <div id="tt-instant-answers-widget" data-testid={`nkn-product-f-answers-widget`}></div>
            <div id="tt-qa-list" data-testid={`nkn-product-f-qa-list`}></div>
          </Col>
        </Row>
      </Container>
    </Tab.Pane>
  );
};

export default ProductForumTab;
