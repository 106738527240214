import classNames from 'classnames';
import { Col } from 'react-bootstrap';
import styles from './LNEArticlePage.module.scss';
import { ILNEQuoteColumnProps } from './types/ILNEQuoteColumnProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';

const LNEQuoteColumn = ({ data: { quoteContent, uid }, numColumns }: ILNEQuoteColumnProps) => {
  const [containerRef, isVisible] = useAnimation();

  if (!Boolean(quoteContent?.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <Col
      data-testid="lne-quote-column"
      ref={containerRef as any}
      className={classNames('animate', 'content-block', { 'in-view': isVisible })}
      xs={12}
      md={12 / numColumns}
    >
      <div className={styles['lne-quote-content']} key={`${uid}`}>
        <blockquote dangerouslySetInnerHTML={{ __html: quoteContent }}></blockquote>
      </div>
    </Col>
  );
};

export default LNEQuoteColumn;
