import { FileDTO } from './CommonFieldsDTO';
import { ICLPBrow, ITopLevelNavigationBlockDTO, ISecondLevelNavigationBlockDTO } from '../interfaces/ICLPBrow';
import { TCLPBrow, TTopLevelBrowNavigationBlocks, TSecondLevelNavigationBlocks } from '../types/TCLPBrow';

export const CLPBrowDTO = (clpBrowTab?: ICLPBrow): TCLPBrow | undefined => {
  if (!clpBrowTab) {
    return;
  }

  return {
    contentTypeUid: clpBrowTab._content_type_uid,
    title: clpBrowTab.title,
    iconImage: clpBrowTab.icon_image ? FileDTO(clpBrowTab?.icon_image) : null,
    menuLink: clpBrowTab.menu_link,
    topLevelBrowNavigationBlocks: clpBrowTab.top_level_brow_navigation_block.map(TopLevelBrowNavigationBlockDTO),
    uid: clpBrowTab.uid,
  };
};

export const TopLevelBrowNavigationBlockDTO = (
  topLvlBlock: ITopLevelNavigationBlockDTO,
): TTopLevelBrowNavigationBlocks => {
  const topNavItem = topLvlBlock.top_level_brow_navigation_item;
  return {
    menuLink: topNavItem.menu_link,
    secondLevelNavigationBlocks: topNavItem.second_level_brow_navigation_block.map(SecondLevelBrowNavigationBlockDTO),
    uid: topNavItem._metadata.uid,
  };
};

export const SecondLevelBrowNavigationBlockDTO = (
  secondLvlBlock: ISecondLevelNavigationBlockDTO,
): TSecondLevelNavigationBlocks => {
  const secondLvlNavItem = secondLvlBlock.second_level_brow_navigation_item;
  return {
    subMenuLink: secondLvlNavItem.sub_menu_link,
    uid: secondLvlNavItem._metadata.uid,
  };
};
