import React, { useRef } from 'react';
import classNames from 'classnames';
import { Col } from 'react-bootstrap';
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Slider, { Settings } from 'react-slick';
import styles from './LNEArticlePage.module.scss';
import { ILNEImageDisplayProps } from './types/ILNEImageDisplayProps';
import useAnimation from '../../../../helpers/hooks/useAnimation';
import { tablet } from '../../../../helpers/utils/screensizes';
import { TLNEImageDisplayGroup } from '../../../../types/TLNEImageDisplay';
import sliderStyles from '../LNESlider.module.scss';

const settings: Settings = {
  dots: false,
  arrows: true,
  infinite: true,
  centerMode: false,
  centerPadding: '0px',
  adaptiveHeight: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const LNEImageBlockCaption = (image: TLNEImageDisplayGroup) => {
  return (
    <div data-testid="lne-image-display-caption" className={styles['lne-caption']}>
      {Boolean(image?.authorsGroup?.length) &&
        image?.authorsGroup?.map((author, authorIndex) => {
          const authorReference = author?.authorReference?.[0];

          if (Boolean(authorReference?.firstName) && Boolean(authorReference?.lastName)) {
            return (
              <p className={styles.author} key={authorIndex}>
                &copy;{' '}
                <span itemProp="creator">
                  {authorReference?.firstName} {authorReference?.lastName}
                </span>
              </p>
            );
          } else return null;
        })}
      {Boolean(image?.caption) && (
        <div className={styles.caption}>
          <div
            className={`${styles['caption-content']} flipboard-remove`}
            dangerouslySetInnerHTML={{ __html: image?.caption }}
          ></div>
        </div>
      )}
    </div>
  );
};

export const LNEImageBlock = (image: TLNEImageDisplayGroup) => {
  return (
    <>
      <div className={styles['lne-image-display-group']}>
        <picture>
          {image?.mobileImage?.url && <source media={`(max-width: ${tablet}px`} srcSet={image?.mobileImage?.url} />}
          <img loading="lazy" src={image?.desktopImage?.url} alt={image?.imageAlt} />
        </picture>
      </div>
      <LNEImageBlockCaption {...image} />
    </>
  );
};

export const LNEImageBlockLightbox = (image: TLNEImageDisplayGroup) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  return (
    <div className={styles.lightbox}>
      <img ref={imageRef} src={image?.desktopImage?.url} style={{ display: 'none', position: 'absolute' }} />
      <Item
        original={image?.desktopImage?.url}
        thumbnail={image?.desktopImage?.url}
        width={imageRef.current ? imageRef.current.naturalWidth : 'auto'}
        height={imageRef.current ? imageRef.current.naturalHeight : 'auto'}
        caption={image?.caption || ' '} //this avoids to display the alt text if there is no caption
        alt={image?.imageAlt}
      >
        {({ ref, open }) => (
          <div className={styles['lne-image-display-group']} onClick={open}>
            <div className={styles['img-wrap']}>
              <div className={styles['img-and-zoom-icon']}>
                <picture>
                  {image?.mobileImage?.url && (
                    <source media={`(max-width: ${tablet}px`} srcSet={image?.mobileImage?.url} />
                  )}
                  <img
                    loading="lazy"
                    src={image?.desktopImage?.url}
                    alt={image?.imageAlt}
                    ref={ref as React.MutableRefObject<HTMLImageElement>}
                  />
                </picture>
                <span className="ss-icon ss-zoomin zoom-icon"></span>
              </div>
            </div>
            <LNEImageBlockCaption {...image} />
          </div>
        )}
      </Item>
    </div>
  );
};

const LNEImageDisplay = ({ data, numColumns }: ILNEImageDisplayProps) => {
  const [containerRef, isVisible] = useAnimation();
  const nonExpiredImages = data?.imageDisplayGroup?.filter((image) => !image.isExpired);
  const numImages = nonExpiredImages?.length;

  if (!Boolean(numImages)) {
    return false as unknown as JSX.Element;
  } else if (numImages > 1) {
    return (
      <Col
        ref={containerRef as any}
        className={classNames(
          'animate',
          'content-block',
          { 'in-view': isVisible },
          styles['lne-image-display'],
          sliderStyles['lne-slider'],
        )}
        xs={12}
        md={12 / numColumns}
      >
        {data?.lightbox ? (
          <Gallery
            options={{ bgOpacity: 1 }}
            withCaption={true}
            uiElements={[
              {
                onInit: (el, pswpInstance) => {
                  // slick slider infinite = true setting creates a bunch of unwanted cloned slides
                  // that show up in the lightbox but not in slick. this code removes those slides
                  // from the lightbox.
                  const data = pswpInstance?.options?.dataSource as object[];
                  const realLength = (data.length - 1) / 2;
                  pswpInstance.options.dataSource = data.slice(0, realLength);
                },
              },
            ]}
          >
            <Slider {...settings}>
              {nonExpiredImages?.map((image, index) => {
                if (!Boolean(image)) {
                  return false as unknown as JSX.Element;
                }

                return <LNEImageBlockLightbox {...image} key={index} />;
              })}
            </Slider>
          </Gallery>
        ) : (
          <Slider {...settings}>
            {nonExpiredImages?.map((image, index) => {
              if (!Boolean(image)) {
                return false as unknown as JSX.Element;
              }

              return <LNEImageBlock {...image} key={index} />;
            })}
          </Slider>
        )}
      </Col>
    );
  } else {
    return (
      <>
        {nonExpiredImages?.map((image, index) => {
          if (!Boolean(image)) {
            return false as unknown as JSX.Element;
          }

          return (
            <Col
              ref={containerRef as any}
              className={classNames('animate', 'content-block', { 'in-view': isVisible }, styles['lne-image-display'])}
              key={index}
            >
              {data?.lightbox ? (
                <Gallery options={{ bgOpacity: 1 }} withCaption={true}>
                  <LNEImageBlockLightbox {...image} key={index} />
                </Gallery>
              ) : (
                <LNEImageBlock {...image} key={index} />
              )}
            </Col>
          );
        })}
      </>
    );
  }
};

export default LNEImageDisplay;
