import React from 'react';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';
import Slider, { Settings } from 'react-slick';
import styles from './LNEPromotionFooter.module.scss';
import { ILNEPromotionFooterProps } from './types/ILNEPromotionFooterProps';
import { tablet, desktop } from '../../../../helpers/utils/screensizes';
import sliderStyles from '../LNESlider.module.scss';

const settings: Settings = {
  dots: false,
  arrows: true,
  infinite: true,
  centerMode: false,
  centerPadding: '0px',
  adaptiveHeight: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: desktop,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: tablet,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const LNEPromotionFooter = ({ data }: ILNEPromotionFooterProps) => {
  if (!data || !Boolean(data)) {
    return false as unknown as JSX.Element;
  }
  settings.infinite = Boolean(data.itemsGroup.length > 3);
  if (settings.responsive?.[0]?.settings) {
    (settings.responsive[0].settings as Settings).infinite = Boolean(data?.itemsGroup?.length > 2);
  }
  return (
    <div className={`${styles['lne-promotion-footer']} ${sliderStyles['lne-slider']}`}>
      <Container>
        {Boolean(data?.headline) && (
          <Row>
            <Col>
              <h2>{data?.headline}</h2>
            </Col>
          </Row>
        )}
        <Row>
          <Slider {...settings}>
            {data?.itemsGroup.map((item, index) => (
              <div
                className={styles.item}
                data-testid="`lne-promotion-footer-item"
                key={`lne-promotion-footer-item-${index}`}
              >
                {item?.imagePath?.url && <img src={item.imagePath.url} loading="lazy" alt={item.imageAltText} />}
                {item?.itemTitle && <h3>{item.itemTitle}</h3>}
                {item?.itemDescription && <p>{item.itemDescription}</p>}
                {Boolean(item.linkUrl && item.linkCopyText) && (
                  <Link href={item.linkUrl}>
                    <a className={styles.link} target={item.openInNewWindow ? '_blank' : '_self'}>
                      {item.linkCopyText}
                      <i className={styles.icon} />
                    </a>
                  </Link>
                )}
              </div>
            ))}
          </Slider>
        </Row>
      </Container>
    </div>
  );
};

export default LNEPromotionFooter;
