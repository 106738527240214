import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Dropdown, Col } from 'react-bootstrap';
import styles from './PageFilter.module.scss';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export enum colorThemes {
  light,
  dark,
}
export enum widths {
  fit,
  fill,
}
export interface PageFilterProps {
  uid: string;
  id?: string;
  className?: string;
  colorTheme: colorThemes;
  filterOptions: [];
  queryVar: string;
  defaultOption?: string;
  variant?: string;
  columns?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  width: widths;
}

const PageFilter: FC<PageFilterProps> = ({
  uid,
  id,
  className,
  colorTheme,
  filterOptions,
  queryVar,
  defaultOption,
  variant,
  columns,
  width,
}) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'page-filter' });
  const toggleText = defaultOption
    ? defaultOption
    : formatMessage({ id: 'press-room-list-page.default_option', defaultMessage: 'Select' });
  const [selectedFilter, setSelectedFilter] = useState(toggleText);

  useEffect(() => {
    const curFilter = router.query[queryVar] ? router.query[queryVar] : toggleText;
    setSelectedFilter(`${curFilter}`);
  }, []);
  const filterPage = (e: any) => {
    const isActive = selectedFilter === e.target.text;
    const curPath =
      router.asPath.indexOf('?') !== -1 ? router.asPath.substring(0, router.asPath.indexOf('?')) : router.asPath;
    const queryParams = router.query;
    queryParams[queryVar] = `${e.target.text}`;
    delete queryParams['slug'];
    if (!isActive) {
      router.push({
        pathname: curPath,
        query: queryParams,
      });
      setSelectedFilter(`${e.target.text}`);
    }
  };
  const defaultActive = selectedFilter === toggleText ? `${styles.active} ` : '';
  return (
    <Col className={className} uid={uid} id={id} {...columns}>
      <Dropdown className={`${styles.filter_dropdown} ${styles[widths[width]]}`}>
        <Dropdown.Toggle
          variant={variant}
          id={'pressroom-list-page-filter-dropdown'}
          className={`${styles.filter_dropdown_toggle} ${styles[colorThemes[colorTheme]]}`}
        >
          <span>
            {selectedFilter}
            <i className={styles.navigateDownIcon} />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          renderOnMount={true}
          className={`${styles.filter_dropdown_menu} ${styles[colorThemes[colorTheme]]} ${styles[widths[width]]}`}
          data-testid="article-filter-menu"
        >
          <Dropdown.Item
            className={`${defaultActive}${styles.filter_dropdown_item} ${styles[colorThemes[colorTheme]]} ${
              styles[widths[width]]
            }`}
            key={'default-filter'}
            eventKey={'default'}
            onClick={filterPage}
            data-testid={'sub-menu-link-default-dropdown'}
          >
            {toggleText}
          </Dropdown.Item>
          {filterOptions.map((filterOption: any) => {
            const isActive = selectedFilter === filterOption ? `${styles.active} ` : '';
            return (
              <Dropdown.Item
                className={`${isActive}${styles.filter_dropdown_item} ${styles[colorThemes[colorTheme]]}`}
                key={`${filterOption}-filter`}
                eventKey={filterOption}
                onClick={filterPage}
                data-testid={`sub-menu-link-${filterOption}-dropdown`}
              >
                {filterOption}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  );
};

export default PageFilter;
