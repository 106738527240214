import React from 'react';
import { Container, Row, Tab } from 'react-bootstrap';
import styles from './CompatibleTab.module.scss';
import { ICompatibleWithTabProps } from './types/ICompatibleWithTabProps';
import { useData } from '../../../../../helpers/hooks/useData';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { TNikonProduct, TNikonVariant } from '../../../../../types/TNikonProduct';
import ProductItem from '../../../atoms/ProductItem';

const CompatibleWithTab = ({ product }: ICompatibleWithTabProps) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const [compatibleWithProducts, isValid] = useData<TNikonVariant, TNikonProduct[]>(product.variants[0], {
    return: 'attributes.compatibleWithProducts',
  });

  if (isValid) {
    return (
      <Tab.Pane data-testid="container" eventKey="compatible-with" className="productsTabPane">
        <h2 data-testid="title">{formatMessage({ id: 'compatibleWith.title', defaultMessage: 'Compatible With' })}</h2>
        <Container className={styles.compatibleWithContainer}>
          <Row className={styles['row']} as="ul">
            {compatibleWithProducts.map((item, index) => (
              <ProductItem key={`compatible-with-item-${item.productId || index}`} product={item} />
            ))}
          </Row>
        </Container>
      </Tab.Pane>
    );
  } else {
    return <></>;
  }
};

export default CompatibleWithTab;
