import React, { useEffect } from 'react';
import Head from 'next/head';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './StaticContentPage.module.scss';
import { IStaticContentPageProps } from './types/IStaticContentPageProps';
import SkipContent from '../atoms/SkipContent/SkipContent';
import CLPBrow from '../CLPBrow';
import ContentBlocks from '../ContentBlocks';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const StaticContentPage = ({ data }: IStaticContentPageProps) => {
  const {
    metaData: { metaTitle, metaDescription, metaImage, metaKeywords },
  } = data;

  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (window !== undefined && data?.title && !accountLoading) {
      sendGTMEvent({
        event: 'page_view',
        pageName: data?.title,
        pageCategory: data.title,
        pageType: PAGE_VIEW_EVENTS.STATIC_PAGE.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading, data?.title]);

  return (
    <div data-testid="static_content_page" className={styles.staticContentPage}>
      <SkipContent />
      {data.metaData && (
        <Head>
          {metaTitle && <title>{metaTitle}</title>}
          {metaDescription && <meta name="description" content={metaDescription} />}
          {metaKeywords && <meta name="keywords" content={metaKeywords} />}
          {metaImage && <meta property="og:image" content={metaImage.url} />}
        </Head>
      )}
      {data.promoBanner && data?.promoBanner && <PromoBanner data={data.promoBanner} />}
      {data?.header && <Header data={data.header} />}
      {data.staticContentBrow && data?.staticContentBrow && (
        <CLPBrow data={data?.staticContentBrow} isStaticContentBrow={true} />
      )}
      <main
        className="static-content-page-main-content"
        data-testid="static-content-page-main-content"
        id="nkn-main-content"
        tabIndex={-1}
      >
        <ContentBlocks data={data.contentBlocks} />
      </main>
      {data?.footer && <Footer data={data.footer} />}
    </div>
  );
};

export default StaticContentPage;
