import React, { FC, useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { Col } from 'react-bootstrap';
import styles from './PageSearch.module.scss';
import SearchIcon from '../../../icons/search';
export enum searchTypes {
  autoComplete,
  filter,
  redirect,
}
export interface PageSearchProps {
  uid: string;
  id?: string;
  className?: string;
  searchType: searchTypes;
  redirectPath?: string;
  label?: string;
  placeHolderText?: string;
  showSearchButton: boolean;
  searchOptions: {
    queryVar: string;
  };
  columns?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}

const PageSearch: FC<PageSearchProps> = ({
  uid,
  id,
  className,
  searchType,
  redirectPath,
  label,
  placeHolderText,
  showSearchButton,
  searchOptions,
  columns,
}) => {
  const router = useRouter();
  const labelElement = label ? <label htmlFor={id}>{label}</label> : undefined;
  const searchTypeClass = searchType === searchTypes.filter ? styles.filter : styles.auto_complete;
  const [showClearButton, setShowClearButton] = useState(undefined as any);
  const searchInput = useRef<HTMLInputElement | null>(null);

  const pageFilter = () => {
    // @ts-ignore
    const searchTerm = searchInput?.current?.value;
    const queryParams = router.query;
    const queryVar = searchOptions.queryVar;
    const curPath =
      router.asPath.indexOf('?') !== -1 ? router.asPath.substring(0, router.asPath.indexOf('?')) : router.asPath;
    delete queryParams['slug'];
    delete queryParams['offset'];
    delete queryParams['limit'];
    if (!searchTerm) {
      delete queryParams[queryVar];
    } else {
      queryParams[queryVar] = searchTerm;
    }
    router.push({
      pathname: curPath,
      query: queryParams,
    });
  };
  const clearPageFilter = () => {
    const queryParams = router.query;
    const queryVar = searchOptions.queryVar;
    const curPath =
      router.asPath.indexOf('?') !== -1 ? router.asPath.substring(0, router.asPath.indexOf('?')) : router.asPath;
    delete queryParams['slug'];
    delete queryParams[queryVar];
    if (!searchInput?.current?.value) {
      return;
    }
    searchInput.current.value = '';
    router.push({
      pathname: curPath,
      query: queryParams,
    });
    setShowClearButton(null);
    searchInput.current.focus();
  };
  const redirectPage = () => {
    if (searchInput.current?.value.length === 0) {
      return;
    }
    const searchTerm = searchInput?.current?.value;
    const queryVar = searchOptions.queryVar;
    const queryParams = router.query;
    delete queryParams['slug'];
    queryParams[queryVar] = searchTerm;
    router.push({
      pathname: redirectPath,
      query: queryParams,
    });
  };

  const inputOnKeyHandler = (e: any) => {
    if (searchInput.current?.value.length === 0) {
      setShowClearButton(null);
      return;
    }
    setShowClearButton(`${styles.show}`);
    if (e.keyCode === 13) {
      switch (searchType) {
        case searchTypes.autoComplete:
          break;
        case searchTypes.filter:
          pageFilter();
          break;
        case searchTypes.redirect:
          redirectPage();
          break;
      }
    }
  };
  const searchButtonClickHandler = () => {
    switch (searchType) {
      case searchTypes.autoComplete:
        break;
      case searchTypes.filter:
        pageFilter();
        break;
      case searchTypes.redirect:
        redirectPage();
        break;
    }
  };
  const setSearchValOnLoad = () => {
    const searchValue = router.query['q'];
    if (!searchValue) {
      return;
    }
    if (!searchInput.current) {
      return;
    }
    searchInput.current.value = `${searchValue}`;
    setShowClearButton(`${styles.show}`);
    searchInput.current.focus();
  };

  useEffect(() => {
    setSearchValOnLoad();
  }, []);
  /*** TODO - MOVE AUTO COMPLETE FUNCTIONALITY FROM GLOSSARY HERE ***/

  const searchButton = (
    <button className={styles.search_icon} onClick={searchButtonClickHandler} data-search-id={id}>
      <SearchIcon className={styles.search_icon} />
    </button>
  );
  return (
    <Col uid={uid} className={`${className} ${styles.search_wrapper} ${searchTypeClass}`} {...columns}>
      {labelElement}
      {showSearchButton && searchButton}
      <input
        id={id}
        autoComplete="off"
        type="text"
        className={styles.search_input}
        placeholder={placeHolderText}
        onKeyUp={inputOnKeyHandler}
        ref={searchInput}
      />
      <button
        type="button"
        className={`${styles.clear_search} ${showClearButton}`}
        onClick={clearPageFilter}
        data-search-id={id}
      >
        <span className="label">×</span>
      </button>
    </Col>
  );
};

export default PageSearch;
