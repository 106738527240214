import React, { useContext } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './FullTechSpecs.module.scss';
import { SpecsGroup } from '../../../../../../../backend/commerce-commercetools/interfaces/specsMapper/ISpecsMapper';
import { getBooleanSanitizedValue } from '../utils';

type Props = { spec: SpecsGroup; eventKey: string };

const FullTechSpecItem: React.FC<Props> = ({ spec, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey?.includes(eventKey);

  const { formatMessage } = useFormat({ name: 'common' });

  if (!spec.specs?.length || spec.specs.every(({ value }) => !value)) return null;

  return (
    <Accordion.Item eventKey={eventKey} className={styles['accordion-item']}>
      <button onClick={decoratedOnClick} className={styles['accordion-item-header']}>
        <p className={styles['accordion-item-header-title']}> {spec.displayName}</p>
        {!isCurrentEventKey ? (
          <PlusIcon width={17.5} strokeWidth={4} data-testid="accordion-item-plus-icon" />
        ) : (
          <MinusIcon width={17.5} strokeWidth={4} data-testid="accordion-item-minus-icon" />
        )}
      </button>
      <Accordion.Body className={styles['accordion-item-body']}>
        <ul className={styles['accordion-item-body-list']}>
          {spec.specs?.map((spec, index) => (
            <li key={index} className={styles['accordion-item-body-item']}>
              <span className={styles['accordion-item-body-item-title']}> {spec.displayName}</span>
              <div dangerouslySetInnerHTML={{ __html: getBooleanSanitizedValue(spec.value, formatMessage) }} />
            </li>
          ))}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
};
export default FullTechSpecItem;
