import { FreeEntryBlockDTO } from '../../DTO/FreeEntryDTO';
import { LNEImageDisplayBlockDTO } from '../../DTO/LNEImageDisplayDTO';
import { LNEQuoteColumnBlockDTO } from '../../DTO/LNEQuoteColumnDTO';
import { LNERichTextColumnBlockDTO } from '../../DTO/LNERichTextColumnDTO';
import { LNEVideoDisplayBlockDTO } from '../../DTO/LNEVideoDisplayDTO';
import { OneToFourColumnBlockDTO } from '../../DTO/OneToFourColumnDTO';

interface ILNEContentBlockDTOReferences<T> {
  [key: string]: T;
}

export const LNE_CONTENT_BLOCKS_DTO_REFERENCES: ILNEContentBlockDTOReferences<any> = {
  free_entry_block: FreeEntryBlockDTO,
  lne_image_display_block: LNEImageDisplayBlockDTO,
  one_to_four_columns_block: OneToFourColumnBlockDTO,
  lne_quote_column_block: LNEQuoteColumnBlockDTO,
  lne_rich_text_column_block: LNERichTextColumnBlockDTO,
  lne_video_display_block: LNEVideoDisplayBlockDTO,
};
