import { PageMetaDataDTO } from './CommonFieldsDTO';
import { ContentBlocksDTO } from './ContentBlocksDTO';
import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { StaticContentBrowDTO } from './StaticContentBrowDTO';
import { IStaticContentPage } from '../interfaces/IStaticContentPage';
import { TStaticContentPage } from '../types/TStaticContentPage';

export const StaticContentPageDTO = (data: IStaticContentPage): TStaticContentPage => ({
  contentTypeUid: 'static_content_page',
  contentBlocks: ContentBlocksDTO(data.content_blocks),
  fileName: data?.file_name || undefined,
  footer: data?.footer_reference?.[0] ? FooterDTO(data.footer_reference[0]) : undefined,
  header: data?.header_reference?.[0] ? HeaderDTO(data.header_reference[0]) : undefined,
  pageSlug: data.page_slug,
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  staticContentBrow: data?.static_content_brow_reference?.[0]
    ? StaticContentBrowDTO(data.static_content_brow_reference[0])
    : undefined,
  metaData: PageMetaDataDTO(data.meta_data),
  uid: data.uid,
  title: data.title,
});
