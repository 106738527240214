import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useFormat } from 'helpers/hooks/useFormat';
import { TPopSupportTab } from 'types/TProductOverviewPage';
import styles from './ProductArchivedBanner.module.scss';

const ProductArchivedBanner = ({ locale, support }: { locale: string; support: TPopSupportTab[] }) => {
  const { formatMessage } = useFormat({ name: 'product' });

  const itemWithProductManual = support.find(({ productManual }) => productManual?.length);
  const productManualHref = itemWithProductManual?.productManual?.[0]?.manualUrl || '';

  const productSupportLink = formatMessage({
    id: 'product.support.link',
    defaultMessage: '<a href="https://www.nikonproductsupport.com/s/?language={locale}">Product Support</a>',
    values: { locale },
  });

  const productManualLink = formatMessage({
    id: 'product.manual.link',
    defaultMessage: `<a href={${productManualHref}} target="_blank">Product Manual</a>`,
    values: {
      link: productManualHref,
    },
  });

  return (
    <div className={styles.banner} data-testid="banner">
      <div className={styles['banner-text']}>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body data-testid="tooltip-text" className="text-center">
                {formatMessage({
                  id: 'product.archived.tooltipInfoMessage',
                  defaultMessage: 'A discontinued product that is no longer available for new retail sales.',
                })}
              </Popover.Body>
            </Popover>
          }
          delay={150}
        >
          <span className={styles['banner-icon']} data-testid="info-icon">
            i
          </span>
        </OverlayTrigger>
        {formatMessage({
          id: 'product.archived',
          defaultMessage: 'This product has been archived',
        })}
      </div>

      <div dangerouslySetInnerHTML={{ __html: productSupportLink }} />
      {productManualHref && <div dangerouslySetInnerHTML={{ __html: productManualLink }} />}
    </div>
  );
};

export default ProductArchivedBanner;
