import { useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import { Nav, Navbar } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import useTouchDevice from 'helpers/hooks/useTouchDevice';
import styles from './CLPBrow.module.scss';
import SecondLevelNavBlock from './SecondLevelNavBlock';
import { ICLPBrowProps } from './types/ICLPBrowProps';
import { MORE_TAB_UID, extractTopLevelBrowNavigationBlocks, extractRedirectURL } from './utils';

const cx = classNames.bind(styles);

const CLPBrow = ({ data, isStaticContentBrow }: ICLPBrowProps) => {
  const router = useRouter();
  const { isTouchDevice } = useTouchDevice();
  const activePath = isStaticContentBrow ? router.asPath.split('/content')[1] : router.asPath.slice(2);

  const topLevelBrowNavigationBlocks = data && extractTopLevelBrowNavigationBlocks(data.topLevelBrowNavigationBlocks);
  const menuItemsLength = topLevelBrowNavigationBlocks?.length || 0;
  const [menuDisplayStatus, setMenuDisplayStatus] = useState(Array(menuItemsLength).fill(false));

  const updateMenuDisplayStatusOnClick = (index: number) => {
    setMenuDisplayStatus((prevState) => prevState.map((item, idx) => (idx === index ? !item : false)));
  };

  const updateMenuDisplayStatusOnHover = (value: boolean, index: number) => {
    setMenuDisplayStatus((prevState) => prevState.map((item, idx) => (idx === index ? value : false)));
  };

  if (!data) {
    return <></>;
  }

  return (
    <Navbar
      expand="md"
      className={cx(styles.brow, { isStaticContentBrow: isStaticContentBrow })}
      data-testid="clp-brow"
    >
      <div className={styles.shopTab}>
        <Navbar.Brand data-testid="clp-brow-desktop-brand" className={styles.navbarBrand}>
          {data.iconImage && (
            <img
              src={data.iconImage.url}
              title={data.iconImage.title}
              data-testid="clpBrow-logo-img"
              width={78}
              height={68}
              alt={data.iconImage.title}
            />
          )}
          {data.title}
        </Navbar.Brand>
      </div>

      <Navbar.Toggle aria-controls="brow-nav" className={styles.mobileMenuButton}>
        Menu
        <span className={`${styles.browMobileIcon} ${styles['mobile-menu-navdown-icon']}`}></span>
      </Navbar.Toggle>

      <Navbar.Collapse id="brow-nav" className={cx('justify-content-end', { isTouchDevice: isTouchDevice })}>
        <ul
          id="product-tablist"
          role="tablist"
          className={cx(`justify-content-end ${styles.productTabList} ${styles.collapse}`, {
            has3OrLessMenuItems: menuItemsLength - 1 <= 3,
            has4MenuItems: menuItemsLength - 1 === 4,
            hasMoreThan4Items: menuItemsLength - 1 > 4,
          })}
          data-testid="clp-brow-menu"
        >
          {topLevelBrowNavigationBlocks?.map((topLevelNav, index) => {
            if (Boolean(topLevelNav.secondLevelNavigationBlocks.length > 0)) {
              return (
                <li
                  key={`topLvlNav-${topLevelNav.uid}-${index}`}
                  role="tab"
                  className={cx('item subnav-tab nav-item', {
                    moreTab: topLevelNav.uid === MORE_TAB_UID,
                    menuTab: topLevelNav.uid !== MORE_TAB_UID,
                  })}
                  onMouseOver={() => !isTouchDevice && updateMenuDisplayStatusOnHover(true, index)}
                  onMouseOut={() => !isTouchDevice && updateMenuDisplayStatusOnHover(false, index)}
                  data-testid={`clpBrow-menu-${topLevelNav.uid}`}
                >
                  <Nav.Link
                    href="#"
                    className={cx({
                      'item-link-wrap subnav-button': true,
                      collapsed: !menuDisplayStatus[index],
                    })}
                    id={`subnav-button-${index}`}
                    onClick={() => updateMenuDisplayStatusOnClick(index)}
                    data-testid={`clpBrow-menu-link-${topLevelNav.uid}`}
                  >
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {topLevelNav.menuLink.title}
                      <span className={`${styles['brow-navdown-icon']}`}></span>
                    </span>
                  </Nav.Link>

                  <Nav.Link
                    href="#"
                    className={cx({
                      'item-link-wrap mobile-subnav-button subnav-button-toggle': true,
                      collapsed: !menuDisplayStatus[index],
                    })}
                    id={`brow-subnav-${index}`}
                    onClick={() => updateMenuDisplayStatusOnClick(index)}
                    data-testid={`clpBrow-mobile-menu-link-${topLevelNav.uid}`}
                  >
                    {topLevelNav.menuLink?.title}
                    <span className={`${styles.browMobileIcon} ${styles['brow-ddtoggler-icon']}`}></span>
                  </Nav.Link>

                  <Collapse in={menuDisplayStatus[index]} className="subnav-dropdown">
                    <div>
                      <SecondLevelNavBlock
                        topLevelNav={topLevelNav}
                        topLevelNavIdx={index}
                        isStaticContentBrow={isStaticContentBrow}
                      />
                    </div>
                  </Collapse>
                </li>
              );
            }

            return (
              <li
                key={`topLevelNav-${topLevelNav.uid}-${index}`}
                role="tab"
                className={`item ${styles.menuTab}`}
                data-testid={`clpBrow-menu-${topLevelNav.uid}`}
              >
                <Nav.Link
                  href={extractRedirectURL(topLevelNav.menuLink.href)}
                  className={cx({ active: activePath === topLevelNav.menuLink.href })}
                  data-testid={`clpBrow-menu-link-${topLevelNav.uid}`}
                >
                  {topLevelNav.menuLink.title}
                </Nav.Link>
              </li>
            );
          })}

          <li
            role="tab"
            className={cx(`d-md-block item nav-item ${styles.shopLinkTab}`, { 'd-none': !isStaticContentBrow })}
          >
            <Nav.Link
              className={`${styles.shopLink} nav-link btn btn-yellow rounded-pill d-md`}
              href={data.menuLink.href}
            >
              {data.menuLink.title}
            </Nav.Link>
          </li>
        </ul>
      </Navbar.Collapse>

      {!isStaticContentBrow && (
        <div className={`d-md-none ${styles.mobileShopTab}`}>
          <div className={styles.productInfo}>
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            {data.title}
          </div>
          <Nav.Link className={`${styles.shopLink}  nav-link btn btn-yellow rounded-pill`} href={data.menuLink.href}>
            {data.menuLink.title}
          </Nav.Link>
        </div>
      )}
    </Navbar>
  );
};
export default CLPBrow;
