import { TTopLevelBrowNavigationBlocks } from 'types/TCLPBrow';

export const MORE_TAB_UID = 'more-tab';

export const extractTopLevelBrowNavigationBlocks = (topLevelBrowNavigationBlocks: TTopLevelBrowNavigationBlocks[]) => {
  const dropdownMenu = topLevelBrowNavigationBlocks;
  if (dropdownMenu.length > 2 && dropdownMenu[dropdownMenu.length - 1].uid !== MORE_TAB_UID) {
    dropdownMenu.push({
      menuLink: { title: 'More', href: '#' },
      secondLevelNavigationBlocks: extractSecondLvlNav(dropdownMenu.slice(2)),
      uid: MORE_TAB_UID,
    });
  }
  return dropdownMenu;
};

export const extractSecondLvlNav = (topLevelBrowNavigationBlock: TTopLevelBrowNavigationBlocks[]) =>
  topLevelBrowNavigationBlock.map(({ menuLink, uid }) => {
    return {
      subMenuLink: menuLink,
      uid,
    };
  });

export const extractRedirectURL = (url: string) => {
  if (isInHttpsUrlFormat(url)) {
    return url;
  }
  if (url.startsWith('/')) {
    url = url.substring(1);
  }
  return `/${url}`;
};

export const isInHttpsUrlFormat = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};
