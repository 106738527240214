import { Col, Container, Row, Tab } from 'react-bootstrap';
import { RATINGS_BLOCK_STATUS } from 'helpers/constants/RatingsAndReviewBlocksConstants';
import { getReviewStatus } from 'helpers/utils/ratingsAndReviewUtils';
import ComingSoon from './ComingSoon';
import styles from './RatingsAndReviewTab.module.scss';
import ReviewSummary from './ReviewSummary';
import { IRatingsAndReviewsTabProps } from './types/IRatingsAndReviewTab';
import { useFormat } from '../../../../../helpers/hooks/useFormat';

const RatingsAndReviewsTab = ({ product, variant }: IRatingsAndReviewsTabProps) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const numberOfReviews = product?.reviewRatingStatistics?.count || 0;
  const userCanReview = variant.attributes.reviewableDate
    ? new Date(variant.attributes.reviewableDate || '') < new Date()
    : true;

  const reviewStatus = getReviewStatus({
    numberOfReviews,
    userCanReview,
  });

  return (
    <Tab.Pane eventKey="ratings-reviews">
      <Container className={`${styles['ratings-reviews-tab-container']}`} data-testid="ratings-reviews-tab-container">
        <Row>
          <Col>
            <h2 className={`${styles['review-heading']}`}>
              {formatMessage({ id: 'ratings.title', defaultMessage: 'Ratings & Reviews' })}
            </h2>

            {reviewStatus === RATINGS_BLOCK_STATUS.COMING_SOON ? <ComingSoon /> : <ReviewSummary />}
          </Col>
        </Row>
      </Container>
    </Tab.Pane>
  );
};

export default RatingsAndReviewsTab;
