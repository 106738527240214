import { CLPBrowDTO } from './CLPBrowDTO';
import { ContentBlocksDTO } from './ContentBlocksDTO';
import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { ICategoryLandingPage } from '../interfaces/ICategoryLandingPage';
import { TCategoryLandingPage } from '../types/TCategoryLandingPage';
import { PageMetaDataDTO } from './CommonFieldsDTO';

export const CategoryLandingPageDTO = (data: ICategoryLandingPage): TCategoryLandingPage => {
  return {
    contentTypeUid: 'category_landing_page',
    categorySlug: data.category_slug,
    clpBrowTabsMenu: CLPBrowDTO(data?.clp_brow_tabs_menu_reference?.[0]),
    contentBlocks: ContentBlocksDTO(data.content_blocks),
    fileName: data.file_name,
    footer: FooterDTO(data.footer_reference[0]),
    header: HeaderDTO(data.header_reference[0]),
    promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
    metaData: PageMetaDataDTO(data.meta_data),
    uid: data.uid,
    title: data.title,
  };
};
