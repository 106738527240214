import { IHomePageSchemaProps } from './types/IHomePageSchemaProps';

const HomePageSchema = ({ seoData }: IHomePageSchemaProps) => {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: seoData.name,
    alternateName: seoData.alternateName,
    url: seoData.url,
    logo: seoData.logo,
    sameAs: seoData.sameAs,
  };

  return <script type="application/ld+json">{JSON.stringify(data)}</script>;
};

export default HomePageSchema;
