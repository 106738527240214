import styles from './ComingSoon.module.scss';

const ComingSoon = () => {
  return (
    <div data-testid="ratingsAndReviews-msg">
      <h2 className={`${styles['ratings-reviews-msg-heading']}`} data-testid="ratings-reviews-msg-heading">
        {`Reviews are coming soon`}
      </h2>
      <p className={`${styles['ratings-reviews-msg-desc']}`} data-testid="ratings-reviews-msg-desc">
        {`We can't wait to hear your firsthand experiences with this product, but first we need to actually get the
        product in your hands! Please check back after it's available for purchase.`}
      </p>
    </div>
  );
};

export default ComingSoon;
