import React, { createRef, useRef, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Container } from 'react-bootstrap';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './GlossaryPage.module.scss';
import { IGlossaryPageProps } from './types/IGlossaryPageProps';
import { useFormat } from '../../../helpers/hooks/useFormat';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const GlossaryPage = ({ data }: IGlossaryPageProps) => {
  const pillRefs = useRef<any>([]);
  const termRefs = useRef<any>([]);
  const searchBox = useRef<any>('');
  const autoCompleteResults = useRef<any>();
  const [activeDefIndex, setActiveDefIndex] = useState(-1);
  const [autoCompleteTerms, setAutoCompleteTerms] = useState<Array<any>>([]);
  const [autoCompleteCount, setAutoCompleteCount] = useState(0);
  const [autoCompleteShow, setAutoCompleteShow] = useState('');
  const router = useRouter();
  const { account, accountLoading } = useAccount();
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });
  const glossaryTerms = data?.glossaryTerms;

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading) {
      sendGTMEvent({
        event: 'page_view',
        pageName: PAGE_VIEW_EVENTS.LE_GLOSSARY.NAME,
        pageCategory: PAGE_VIEW_EVENTS.LE_GLOSSARY.CATEGORY,
        pageType: PAGE_VIEW_EVENTS.LE_GLOSSARY.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading]);

  let curFirstChar = '';
  const alphabetArr = [
    'Numbers',
    ...formatMessage({
      id: 'nav_pill_array',
      defaultMessage: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    }),
  ];
  const lettersWithTerms = [
    ...new Set(
      glossaryTerms?.map((termObject) =>
        isNaN(termObject.glossaryTerm?.charAt(0) as any) ? termObject.glossaryTerm?.charAt(0) : 'Numbers',
      ),
    ),
  ];

  const searchTerms = glossaryTerms ? glossaryTerms.map((termObject) => termObject.glossaryTerm) : [];
  const searchTermHashes = glossaryTerms
    ? glossaryTerms.map(
        (termObject) => `term-${termObject.glossaryTerm.toLowerCase().replaceAll(/[^A-Za-z0-9]+/g, '-')}`,
      )
    : [];
  const pathArr = router.asPath.split('#');
  let termIndex = searchTermHashes?.indexOf(pathArr[1]);
  pillRefs.current = alphabetArr.map((_, i) => pillRefs.current[i] ?? createRef());
  termRefs.current = glossaryTerms?.map((_, i) => termRefs.current[i] ?? createRef());

  const toggleDefinition = (e: any) => {
    const termIndexLocal = e ? e.target.dataset.termindex : termIndex;
    if (activeDefIndex === -1 && termIndexLocal !== -1) {
      const activeDef = termRefs.current[termIndexLocal].current;
      activeDef.classList.add(`${styles['active']}`);
      setActiveDefIndex(termIndexLocal);
      termRefs.current[termIndexLocal].current.focus();
      return;
    }
    if (termIndexLocal !== -1 && activeDefIndex !== -1) {
      const curActive = termRefs.current[activeDefIndex].current;
      const newActive = termRefs.current[termIndexLocal].current;
      curActive.classList.remove(`${styles['active']}`);

      if (activeDefIndex === termIndexLocal) {
        setActiveDefIndex(-1);
        return;
      }

      newActive.classList.add(`${styles['active']}`);
      setActiveDefIndex(termIndexLocal);
    }
  };

  useEffect(() => {
    document.documentElement.classList.add(`${styles['scroll_to_anchor']}`);
    toggleDefinition(null);
  }, []);

  const togglePill = (index: number) => {
    const pillElement = pillRefs.current[index].current;
    const curClassName = pillElement?.classList;
    pillRefs.current.map((element: any) => element.current.classList.remove(`${styles['active']}`));
    pillElement.className = `${curClassName} ${styles['active']}`;
  };

  const searchGlossary = (e: any) => {
    if (e.keyCode === 13 && autoCompleteCount != 0) {
      router.push(autoCompleteTerms[0]['href']);
      toggleDefinition({ target: { dataset: { termindex: autoCompleteTerms[0]['key'] } } });
      setAutoCompleteShow('');
    }
    if (e.keyCode === 40 && autoCompleteTerms.length !== 0) {
      const resultsList = autoCompleteResults.current;
      resultsList.firstChild.querySelector('a').focus({ preventScroll: true });
      return;
    }
    const enteredVal = searchBox.current ? searchBox.current.value.toUpperCase() : '';
    const termsFound = searchTerms
      ? searchTerms
          .flatMap((term, index) => {
            return term.toUpperCase().includes(enteredVal)
              ? { text: term, key: index, href: `#term-${term.toLowerCase().replaceAll(/[^A-Za-z0-9]+/g, '-')}` }
              : false;
          })
          .filter(Boolean)
      : [];
    const numberFound = termsFound ? termsFound.length : 0;
    const resultsText =
      numberFound === 1
        ? formatMessage({ id: 'ac_result', defaultMessage: 'Result' })
        : formatMessage({ id: 'ac_results', defaultMessage: 'Results' });

    if (enteredVal.length > 0) {
      setAutoCompleteCount(`${numberFound} ${resultsText}` as any);
      setAutoCompleteTerms(termsFound);
      setAutoCompleteShow(styles.has_results);
    } else {
      setAutoCompleteCount(0);
      setAutoCompleteTerms([]);
      setAutoCompleteShow('');
    }
  };

  const clearSearchInput = () => {
    searchBox.current.value = '';
    setAutoCompleteCount(0);
    setAutoCompleteTerms([]);
    setAutoCompleteShow('');
    searchBox.current.select();
  };

  const autoCompleteKeyPressHandler = (e: any) => {
    const resultsList = [...autoCompleteResults.current.querySelectorAll('a')];
    const firstResult = resultsList[0];
    const lastResult = resultsList[resultsList.length - 1];
    const curResult = e.target;

    switch (e.keyCode) {
      case 40:
        e.preventDefault();
        const nextResult = curResult !== lastResult ? resultsList[resultsList.indexOf(curResult) + 1] : firstResult;
        nextResult.focus({ preventScroll: true });
        return false;

      case 38:
        e.preventDefault();
        const prevResult = curResult !== firstResult ? resultsList[resultsList.indexOf(curResult) - 1] : lastResult;
        prevResult.focus({ preventScroll: true });
        return false;
    }
  };

  const autoCompleteClickHandler = (e: any) => {
    e.preventDefault();
    const elem = e.target;
    const term = `${elem.innerHTML}`;
    const termIndexLocal = searchTerms.indexOf(term);
    searchBox.current.value = term;
    setAutoCompleteShow('');
    termIndex = termIndexLocal;
    toggleDefinition(null);
  };
  return (
    <div className={styles.staticContentPage}>
      {data.promoBanner && data?.promoBanner && <PromoBanner data={data.promoBanner} />}
      {data?.header && <Header data={data.header} />}
      <main data-testid="glossary_page" className={styles.glossary_page}>
        <Container>
          <section className={styles.glossary_header}>
            <section className={styles.search_area}>
              <span className={styles.pre_label}>
                {formatMessage({
                  id: 'search-label',
                  defaultMessage: 'Search the glossary',
                })}
              </span>
              <div className={`${styles['search_box']} ${autoCompleteShow}`}>
                <section className={styles.search_input}>
                  <span role="status" aria-live="polite" className="sr-only">
                    ${autoCompleteCount}{' '}
                    {formatMessage({
                      id: 'ac_ada-message',
                      defaultMessage: 'is available, use up and down arrow keys to navigate.',
                    })}
                  </span>
                  <input
                    ref={searchBox}
                    autoComplete="off"
                    type="text"
                    className={styles.search_text}
                    placeholder={formatMessage({
                      id: 'search-placeholder',
                      defaultMessage: 'Search',
                    })}
                    onKeyUp={searchGlossary}
                  />
                  <button type="button" className={styles.clear_search}>
                    <span className="label" onClick={clearSearchInput}>
                      ×
                    </span>
                  </button>
                  <div className={styles.search_icon}></div>
                </section>
                <section className={styles.auto_complete}>
                  <span className={styles.auto_complete_count}>{autoCompleteCount}</span>
                  <ul className={styles.auto_complete_list} ref={autoCompleteResults}>
                    {autoCompleteTerms.length > 0
                      ? autoCompleteTerms.map((term, index) => {
                          return (
                            <li
                              onClick={autoCompleteClickHandler}
                              key={`ac-${index}`}
                              onKeyDown={autoCompleteKeyPressHandler}
                            >
                              <Link href={`${term.href}`}>{term.text}</Link>
                            </li>
                          );
                        })
                      : false}
                  </ul>
                </section>
              </div>
            </section>
            <nav className={styles.glossary_nav}>
              {alphabetArr.map((label, i) => {
                const labelText = label === 'Numbers' ? '2' : label;
                const disabledPill = !lettersWithTerms.includes(label);
                return (
                  <Link href={`#${label}`} key={`pill-${label}`}>
                    <button
                      ref={pillRefs.current[i]}
                      id={`${label}_pill`}
                      className={`${label === 'Numbers' ? styles['nav_item_number'] : styles['nav_item']}`}
                      onClick={() => {
                        togglePill(i);
                      }}
                      aria-label={`${formatMessage({
                        id: 'glossary-nav-terms-aria-label',
                        defaultMessage: 'Terms starting with',
                      })} ${label}`}
                      disabled={disabledPill}
                      type="button"
                    >
                      <span>{labelText}</span>
                    </button>
                  </Link>
                );
              })}
            </nav>
          </section>
          <dl className={styles.glossary_list}>
            {data?.glossaryTerms?.map((glossaryItem, index) => {
              const firstChar = isNaN(parseInt(glossaryItem.glossaryTerm?.charAt(0)))
                ? glossaryItem.glossaryTerm?.charAt(0)
                : 'Numbers';
              let sectionAnchor = null;

              if (curFirstChar !== firstChar) {
                curFirstChar = firstChar;
                sectionAnchor = <a id={curFirstChar}></a>;
              }

              const termDef = glossaryItem.glossaryIcon ? (
                <dd id={`definition-${searchTermHashes[index]}`} className={styles.glossary_def}>
                  <img className={styles.glossary_def_image} src={glossaryItem.glossaryIcon} alt={''} />
                  <span className={styles.glossary_def_text}>{glossaryItem.glossaryDef}</span>
                </dd>
              ) : (
                <dd id={`definition-${searchTermHashes[index]}`} className={styles.glossary_def}>
                  <span className={styles.glossary_def_text}>{glossaryItem.glossaryDef}</span>
                </dd>
              );

              return (
                <React.Fragment key={`def-${index}`}>
                  <dt
                    ref={termRefs.current[index]}
                    id={glossaryItem.uid}
                    className={styles['glossary_term']}
                    onClick={toggleDefinition}
                    data-termindex={index}
                  >
                    {sectionAnchor}
                    <Link href={`#${searchTermHashes[index]}`} scroll={false}>
                      <a id={searchTermHashes[index]} data-termindex={index}>
                        <span data-termindex={index} className={styles.glossary_term_label}>
                          {glossaryItem.glossaryTerm}
                        </span>
                      </a>
                    </Link>
                  </dt>
                  {termDef}
                </React.Fragment>
              );
            })}
          </dl>
        </Container>
      </main>
      {data?.footer && <Footer data={data.footer} />}
    </div>
  );
};

export default GlossaryPage;
