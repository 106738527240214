import { Container, Row } from 'react-bootstrap';
import styles from './LNEArticlePage.module.scss';
import LNEImageDisplay from './LNEImageDisplay';
import LNEQuoteColumn from './LNEQuoteColumn';
import LNERichTextColumn from './LNERichTextColumn';
import LNEVideoDisplay from './LNEVideoDisplay';
import { ILNEContentBlocksProps } from './types/ILNEContentBlocksProps';
import { LNEContentBlocksUid } from '../../../../helpers/constants/LNEContentBlocksUid';
import { TFreeEntry } from '../../../../types/TFreeEntry';
import { TLNEImageDisplay } from '../../../../types/TLNEImageDisplay';
import { TLNEQuoteColumn } from '../../../../types/TLNEQuoteColumn';
import { TLNERichTextColumn } from '../../../../types/TLNERichTextColumn';
import { TLNEVideoDisplay } from '../../../../types/TLNEVideoDisplay';
import { TOneToFourColumn } from '../../../../types/TOneToFourColumn';
import FreeEntry from '../../ContentBlocks/FreeEntry';
import OneToFourColumn from '../../ContentBlocks/OneToFourColumn';

const LNEContentBlocks = ({ data }: ILNEContentBlocksProps) => {
  if (!Boolean(data?.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <Container data-testid="lne-content-blocks" className="lne-content-blocks">
      {data.map((rowsBlock, index) => {
        return (
          <Row className={styles['lne-content-block']} key={index}>
            {rowsBlock?.rowBlock?.columnBlocks?.map((block, i) => {
              const key = `lne-content-block-${i}`;
              const numColumns = rowsBlock?.rowBlock?.columnBlocks?.length || 1;
              switch (block?.contentTypeUid) {
                case LNEContentBlocksUid.FREE_ENTRY:
                  return <FreeEntry data={block as unknown as TFreeEntry} key={key} />;
                case LNEContentBlocksUid.IMAGE:
                  return (
                    <LNEImageDisplay data={block as unknown as TLNEImageDisplay} key={key} numColumns={numColumns} />
                  );
                case LNEContentBlocksUid.ONE_TO_FOUR_COLUMNS:
                  return <OneToFourColumn data={block as unknown as TOneToFourColumn} key={key} />;
                case LNEContentBlocksUid.QUOTE:
                  return (
                    <LNEQuoteColumn data={block as unknown as TLNEQuoteColumn} key={key} numColumns={numColumns} />
                  );
                case LNEContentBlocksUid.RICH_TEXT:
                  return (
                    <LNERichTextColumn
                      data={block as unknown as TLNERichTextColumn}
                      key={key}
                      numColumns={numColumns}
                    />
                  );
                case LNEContentBlocksUid.VIDEO: {
                  return (
                    <LNEVideoDisplay data={block as unknown as TLNEVideoDisplay} key={key} numColumns={numColumns} />
                  );
                }

                default:
                  return null;
              }
            })}
          </Row>
        );
      })}
    </Container>
  );
};

export default LNEContentBlocks;
