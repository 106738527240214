import React from 'react';
import { Container, Row, Tab } from 'react-bootstrap';
import styles from './AccessoriesTab.module.scss';
import { IAccessoriesTabProps } from './types/IAccessoriesTabProps';
import { useData } from '../../../../../helpers/hooks/useData';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { TNikonProduct, TNikonVariant } from '../../../../../types/TNikonProduct';
import ProductItem from '../../../atoms/ProductItem';

const AccessoriesTab = ({ product }: IAccessoriesTabProps) => {
  const { formatMessage } = useFormat({ name: 'product' });

  const [relatedAccessoriesProducts, isValidAccessoriesProducts] = useData<TNikonVariant, TNikonProduct[]>(
    product.variants[0],
    {
      return: 'attributes.relatedAccessories',
    },
  );
  const [suppliedAccessoriesProducts, isValidSuppliedProducts] = useData<TNikonVariant, TNikonProduct[]>(
    product.variants[0],
    {
      return: 'attributes.suppliedProducts',
    },
  );

  if (!isValidSuppliedProducts && !isValidAccessoriesProducts) {
    return false as unknown as JSX.Element;
  }

  return (
    <Tab.Pane data-testid="container" eventKey="accessories" className="productsTabPane">
      <h2 data-testid="title">{formatMessage({ id: 'accessoriesTab.title', defaultMessage: 'Accessories' })}</h2>
      {isValidSuppliedProducts && (
        <Container className={`pb-[60px] ${styles.accessoriesContainer}`}>
          <h3 data-testid="supplied-title">
            {formatMessage({ id: 'accessoriesTab.suppliedTitle', defaultMessage: 'Supplied Accessories' })}
          </h3>
          <Row className={styles['row']} as="ul">
            {suppliedAccessoriesProducts.map((item, idx) => (
              <ProductItem key={idx} product={item} />
            ))}
          </Row>
        </Container>
      )}
      {isValidAccessoriesProducts && (
        <Container className={styles.accessoriesContainer}>
          <h3 data-testid="related-title">
            {formatMessage({ id: 'accessoriesTab.relatedTitle', defaultMessage: 'Related Accessories' })}
          </h3>
          <Row className={styles['row']} as="ul">
            {relatedAccessoriesProducts.map((item, index) => (
              <ProductItem key={`accessories-item-${item.productId || index}`} product={item} />
            ))}
          </Row>
        </Container>
      )}
    </Tab.Pane>
  );
};

export default AccessoriesTab;
