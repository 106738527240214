import React from 'react';
import LNEArticleContributorFooter from './LNEArticleContributorFooter';
import LNEArticleHeaderBlock from './LNEArticleHeaderBlock';
import styles from './LNEArticlePage.module.scss';
import LNEArticleRelatedProducts from './LNEArticleRelatedProducts';
import LNEContentBlocks from './LNEContentBlocks';
import { ILNEArticleBodyProps } from './types/ILNEArticleBodyProps';

const LNEArticleBody = ({ data }: ILNEArticleBodyProps) => {
  return (
    <div data-testid="lne-article-body" className={styles['lne-article-body']}>
      <LNEArticleHeaderBlock
        articleFieldsGroup={data?.articleFieldsGroup}
        authors={data?.authorsGroup || []}
        creators={data?.creatorsGroup || []}
        metadataFields={data?.metadataFields}
      />

      <LNEContentBlocks data={data.rowsBlock} />

      {Boolean(!data?.hideAssetContributors && data?.creatorsGroup.length > 0) && (
        <LNEArticleContributorFooter creators={data?.creatorsGroup || []} />
      )}

      {Boolean(data?.relatedProducts?.length) && (
        <LNEArticleRelatedProducts relatedProducts={data?.relatedProducts || []} />
      )}
    </div>
  );
};

export default LNEArticleBody;
