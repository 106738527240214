import classNames from 'classnames';
import { Tab } from 'react-bootstrap';
import OneToFourColumn from 'components/contentstack/ContentBlocks/OneToFourColumn';
import FullTechSpecs from './FullTechSpecs';
import HighlightedTechSpecs from './HighlightedTechSpecs';
import styles from './TechSpecsTab.module.scss';
import { ITechSpecsTabProps } from './types/ITechSpecsTabProps';
import FootNotes from '../../../ContentBlocks/FootNotes';
import FullWidthText from '../../../ContentBlocks/FullWidthText';

const TechSpecsTab = ({ data, product }: ITechSpecsTabProps) => {
  return (
    <Tab.Pane
      eventKey="tech-specs"
      data-testid="tech-specs-tab-panel"
      className={classNames('tech-specs-tab', styles.techSpecsTabPane)}
    >
      <div className={styles['tech-specs']}>
        {data.techSpecsContentBlock.map((block, index) => {
          if (block.defaultTechSpecs) {
            return (
              <div key={`default-tech-specs-${block.defaultTechSpecs.uid}-${index}`} data-testid={`default-tech-specs`}>
                <HighlightedTechSpecs specs={product.specs} />

                <FullTechSpecs specs={product.specs} />
              </div>
            );
          } else if (block.fullWidthText) {
            return (
              <FullWidthText
                data={block.fullWidthText}
                key={`tech-specs-full-width-text-${block.fullWidthText.uid}-${index}`}
              />
            );
          } else if (block.oneToFourColumn) {
            return <OneToFourColumn data={block.oneToFourColumn} key={`tech-specs-1-to-4-col-${index}`} />;
          } else if (block.footnote) {
            return (
              <FootNotes data={block.footnote} key={`tech-specs-footnote-${block.footnote.uid}-${index}`} />
              /*<div
                className={styles['pdp-footnotes']}
                key={`tech-specs-footnote-${block.footnote.uid}-${index}`}
                data-testid={`tech-specs-footnote-${block.footnote.uid}`}
              >
                <h1>Foot notes???</h1>
                <Row className="g-0">
                  <Col>
                    <p className={styles['pdp-footnotes-text']}>{block.footnote.globalFootNotes}</p>
                  </Col>
                </Row>
              </div>*/
            );
          }
        })}
      </div>
    </Tab.Pane>
  );
};

export default TechSpecsTab;
