import { PageMetaDataDTO } from './CommonFieldsDTO';
import { ContentBlocksDTO } from './ContentBlocksDTO';
import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { LNEHeroNavDTO } from './LNEHeroNavDTO';
import { LNEPromotionFooterDTO } from './LNEPromotionFooterDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { ILNEHomepage } from '../interfaces/ILNEHomepage';
import { TLNEHomepage } from '../types/TLNEHomepage';

export const LNEHomepageDTO = (data: ILNEHomepage): TLNEHomepage => ({
  contentTypeUid: 'lne_homepage',
  footer: FooterDTO(data.footer_reference[0]),
  header: HeaderDTO(data.header_reference[0]),
  lneHomepageContentBlocks: ContentBlocksDTO(data.content_blocks),
  heroNav: LNEHeroNavDTO(data.hero_nav),
  metaData: PageMetaDataDTO(data.meta_data),
  promotionFooter: LNEPromotionFooterDTO(data.promotion_footer_reference[0]),
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  ideasAndInspirationCount: data?.ideas_and_inspiration_count,
  productsAndInnovationCount: data?.products_and_innovation_count,
  tipsAndTechniquesCount: data?.tips_and_techniques_count,
  uid: data.uid,
});
