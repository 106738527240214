import { LNEAuthorReferenceDTO } from './LNEAuthorDTO';
import { SeoVideoDataDTO } from './SeoDataDTO';
import {
  ILNEVideoDisplay,
  ILNEVideoDisplayBlock,
  ILNEVideoDisplayGroup,
  ILNEVideoDisplayReference,
} from '../interfaces/ILNEVideoDisplay';
import { TLNEVideoDisplay, TLNEVideoDisplayGroup } from '../types/TLNEVideoDisplay';
import { TVideoLocationForVideo } from '../types/TOneToFourColumn';

export const LNEVideoDisplayBlockDTO = ({ lne_video_display_block }: ILNEVideoDisplayBlock): TLNEVideoDisplay =>
  LNEVideoDisplayReferenceDTO(lne_video_display_block);

export const LNEVideoDisplayReferenceDTO = ({ video_display_reference }: ILNEVideoDisplayReference): TLNEVideoDisplay =>
  LNEVideoDisplayDTO(video_display_reference?.[0]);

export const LNEVideoDisplayGroupDTO = (data: ILNEVideoDisplayGroup): TLNEVideoDisplayGroup => ({
  authorsGroup: data?.authors_group ? data.authors_group?.map(LNEAuthorReferenceDTO) : [],
  autoplay: data?.autoplay,
  controls: data?.controls,
  caption: data?.caption,
  expirationDate: data?.expiration_date || null,
  isExpired: data?.expiration_date ? new Date(data?.expiration_date).valueOf() - new Date().valueOf() < 0 : false,
  loopVideo: data?.loop_video,
  seoData: data?.seo ? SeoVideoDataDTO(data.seo) : null,
  videoLocation: (data.video_location as TVideoLocationForVideo | undefined) || 'YouTube',
  videoOrientation: data?.video_orientation || 'horizontal',
  videoRefId: data?.video_ref_id,
});

export const LNEVideoDisplayDTO = (data: ILNEVideoDisplay): TLNEVideoDisplay => ({
  contentTypeUid: 'lne_video_display_block',
  videoDisplayGroup: data?.video_display_group ? data?.video_display_group?.map(LNEVideoDisplayGroupDTO) : [],
  uid: data?.uid,
});
