import { PageMetaDataDTO } from './CommonFieldsDTO';
import { ContentBlocksDTO } from './ContentBlocksDTO';
import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { ITradeInFormPage } from '../interfaces/ITradeInFormPage';
import { TTradeInFormPage } from '../types/TTradeInFormPage';

export const TradeInFormPageDTO = (data: ITradeInFormPage): TTradeInFormPage => ({
  contentTypeUid: 'trade_in_form_page',
  contentBlocks: ContentBlocksDTO(data.content_blocks),
  fileName: data?.file_name || undefined,
  footer: data?.footer_reference?.[0] ? FooterDTO(data.footer_reference[0]) : undefined,
  header: data?.header_reference?.[0] ? HeaderDTO(data.header_reference[0]) : undefined,
  metaData: PageMetaDataDTO(data.meta_data),
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  title: data?.title,
  uid: data.uid,
});
