import React from 'react';
import Link from 'next/link';
import styles from './LNEHeroNav.module.scss';
import { ILNEHeroNavProps } from './types/ILNEHeroNavProps';
import LNESearchForm from '../LNESearchForm/LNESearchForm';

const LNEHeroNav = ({ data, showSearch }: ILNEHeroNavProps) => {
  return (
    <div className={styles['lne-sub-nav']} style={{ backgroundImage: `url(${data?.navImage?.url})` }}>
      <div className={`container ${styles['lne-hero-nav']}`}>
        <Link href="/learn-and-explore">
          <a>
            <img
              src="/images/lne-logo.png"
              className={`${styles.logo} ${showSearch ? styles['has-search'] : ''}`}
              width="500"
              height="93"
              loading="lazy"
              alt="Nikon Learn & Explore"
            />
          </a>
        </Link>

        {Boolean(data?.navHeadline) && <h1 className={styles.headline}>{data?.navHeadline}</h1>}

        {showSearch && <LNESearchForm placement="hero" />}
      </div>
    </div>
  );
};

export default LNEHeroNav;
