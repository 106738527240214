import { IServiceAdvisoryArticle } from '../interfaces/IServiceAdvisoryArticle';
import { TServiceAdvisoryArticle } from '../types/TServiceAdvisoryArticle';

export const ServiceAdvisoryArticleDTO = (article: IServiceAdvisoryArticle): TServiceAdvisoryArticle => {
  const data = article.data;
  return {
    contentTypeUid: 'service_advisory_page',
    articleClassification: data?.article_classification,
    articleContent: data?.article_content,
    articleDate: data?.article_date,
    articleDateUpdated: data?.article_updated_date,
    articleImage: data?.product_image?.url,
    articleTitle: data?.article_title,
    relatedProduct: data?.related_product,
    slug: data?.slug,
    title: data?.title,
    uid: data?.uid,
  };
};
