import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormat } from 'helpers/hooks/useFormat';
import { TNikonProduct } from 'types/TNikonProduct';
import HighlightedTechSpecItem from './HighlightedTechSpecItem';
import styles from './HighlightedTechSpecs.module.scss';

type Props = { specs: TNikonProduct['specs'] };

const HighlightedTechSpecs: React.FC<Props> = ({ specs }) => {
  const { formatMessage } = useFormat({ name: 'product' });

  return (
    <div data-testid="glance-tech-specs" className={styles['at-a-glance']}>
      <Row>
        <Col>
          <h2>{formatMessage({ id: 'techSpecs.title', defaultMessage: 'Tech Specs' })}</h2>
          <div>
            <ul className={styles['specs-list']}>
              {specs?.map(({ specs }) => {
                return specs
                  .filter(({ glance }) => glance)
                  .map((spec, index) => <HighlightedTechSpecItem key={index} spec={spec} />);
              })}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HighlightedTechSpecs;
