export const messages = {
  title: {
    id: `supportTab.title`,
    defaultMessage: 'Support',
  },
  productRegistration: {
    title: {
      id: `supportTab.productRegistration.title`,
      defaultMessage: 'Product Registration',
    },
    content: {
      id: `supportTab.productRegistration.content`,
      defaultMessage:
        '<strong>Registering your Nikon product</strong> allows us to send you (with your permission) important updates, service information and helpful hints, and it makes it easier should you ever need to call in for help.',
    },
    link: {
      id: `supportTab.productRegistration.link`,
      defaultMessage: 'https://www.nikonproductsupport.com/s/register-product?language=en_US',
    },
    linkText: {
      id: `supportTab.productRegistration.linkText`,
      defaultMessage: 'Register your product online now.',
    },
  },
  answerSite: {
    title: {
      id: `supportTab.answerSite.title`,
      defaultMessage: 'Nikon Answers Site',
    },
    content: {
      id: `supportTab.answerSite.content`,
      defaultMessage:
        '<strong>We store all resolved problems in our solution database.</strong> If you can’t find a relevant answer, feel free to submit a question to our technical support team.',
    },
    link: {
      id: `supportTab.answerSite.link`,
      defaultMessage:
        'https://www.nikonproductsupport.com/s/?language=en_US&_ga=2.142698684.868053290.1690804270-927358829.1687166450',
    },
    linkText: {
      id: `supportTab.answerSite.linkText`,
      defaultMessage: 'View Questions & Answers',
    },
  },
  resources: {
    title: {
      id: `supportTab.resources.title`,
      defaultMessage: 'Resources',
    },
  },
};
