import React, { Dispatch, SetStateAction, useState } from 'react';
import { useRouter } from 'next/router';
import { Form, Offcanvas } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import styles from '../LNEFilters.module.scss';

interface Props {
  isTipsAndTechniques: boolean;
  setIsTipsAndTechniques: Dispatch<SetStateAction<boolean>>;
  isIdeasAndInspiration: boolean;
  setIsIdeasAndInspiration: Dispatch<SetStateAction<boolean>>;
  isProductsAndInnovation: boolean;
  setIsProductsAndInnovation: Dispatch<SetStateAction<boolean>>;
  setShowAll: Dispatch<SetStateAction<boolean>>;
}

const LNECollectionPageFilters = ({
  isTipsAndTechniques,
  setIsTipsAndTechniques,
  isIdeasAndInspiration,
  setIsIdeasAndInspiration,
  isProductsAndInnovation,
  setIsProductsAndInnovation,
  setShowAll,
}: Props) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });

  const handleFilter = (e: any) => {
    const appliedCategoryString = router?.query?.category as string;
    let noDupes = [...new Set(appliedCategoryString?.split(','))];
    if (e.target.checked) {
      noDupes.push(e.target.id);
      noDupes = [...new Set(noDupes)];
    } else {
      const removeIndex = noDupes.indexOf(e.target.id);
      if (removeIndex >= 0) {
        noDupes.splice(removeIndex, 1);
      }
    }
    if (Boolean(noDupes.length)) {
      router.query.category = noDupes.join(',');
      setShowAll(false);
    } else {
      setShowAll(true);
      delete router.query.category;
    }
    // router.push({ pathname: router.asPath?.split('?')?.[0], query: router.query }, undefined, { shallow: true }).then();
    router.push({ pathname: router.asPath?.split('?')?.[0], query: router.query }).then();
  };

  // offcanvas for filters
  const [show, setShow] = useState(false);
  const handleCloseFilters = () => setShow(false);
  const handleShowFilters = () => setShow(true);

  return (
    <div data-testid="lne-filters" className={styles['lne-filters']}>
      <Button variant="outline-dark" onClick={handleShowFilters}>
        {formatMessage({ id: 'filter.by', defaultMessage: 'Filter By' })} <i className="bi bi-sliders"></i>
      </Button>

      <Offcanvas show={show} onHide={handleCloseFilters} className={styles['lne-filters-offcanvas']}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{formatMessage({ id: 'filter.by', defaultMessage: 'Filter By' })}:</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Check
            type="checkbox"
            id="tips-and-techniques"
            label={formatMessage({ id: 'tips-and-techniques', defaultMessage: 'Tips & Techniques' })}
            onChange={(e) => {
              setIsTipsAndTechniques(e.target.checked);
              handleFilter(e);
            }}
            className={'customFormCheckbox'}
            checked={isTipsAndTechniques}
          />
          <Form.Check
            type="checkbox"
            id="ideas-and-inspiration"
            label={formatMessage({ id: 'ideas-and-inspiration', defaultMessage: 'Ideas & Inspiration' })}
            onChange={(e) => {
              setIsIdeasAndInspiration(e.target.checked);
              handleFilter(e);
            }}
            className={'customFormCheckbox'}
            checked={isIdeasAndInspiration}
          />
          <Form.Check
            type="checkbox"
            id="products-and-innovation"
            label={formatMessage({ id: 'products-and-innovation', defaultMessage: 'Products & Innovation' })}
            onChange={(e) => {
              setIsProductsAndInnovation(e.target.checked);
              handleFilter(e);
            }}
            className={'customFormCheckbox'}
            checked={isProductsAndInnovation}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default LNECollectionPageFilters;
