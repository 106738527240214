import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import SkipContent from 'components/contentstack/atoms/SkipContent/SkipContent';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './LNECategoryPage.module.scss';
import LNECategoryPageFilters from './LNECategoryPageFilters';
import { ILNECategoryPageProps } from './types/ILNECategoryPageProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import Pagination from '../../../nikon-ui/atoms/pagination';
import Footer from '../../Footer';
import Header from '../../Header';
import PromoBanner from '../../PromoBanner';
import LNEArticleBlockList from '../LNEArticleBlockList';
import LNECategoryBarAndMenu from '../LNECategoryBarAndMenu/LNECategoryBarAndMenu';
import LNEHeroNav from '../LNEHeroNav';
import LNEPromotionFooter from '../LNEPromotionFooter';
import { getLNECategorySlug } from '../utils';

const LNECategoryPage = ({ data }: ILNECategoryPageProps) => {
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });
  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading && data?.category) {
      sendGTMEvent({
        event: 'page_view',
        pageName: data.category,
        pageCategory: data.category,
        pageType: PAGE_VIEW_EVENTS.LE_CATEGORY.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading, data?.category]);

  return (
    <div data-testid="lne-category-page" className={styles['lne-category-page']}>
      <SkipContent />
      {Boolean(data?.promoBanner) && <PromoBanner data={data.promoBanner} />}
      {Boolean(data?.header) && <Header data={data.header} />}
      {Boolean(data?.heroNav) && <LNEHeroNav data={data.heroNav} showSearch={false} />}
      <LNECategoryBarAndMenu />

      <main
        data-testid="lne-category-page-main-content"
        className={styles['lne-category-page-main-content']}
        id="nkn-main-content"
        tabIndex={-1}
      >
        <Container>
          <h1>
            {formatMessage({
              id: getLNECategorySlug(data?.category),
              defaultMessage: data?.category,
            })}
          </h1>
          <div className={styles['lne-filter-pagination-container']}>
            <LNECategoryPageFilters />
            <div className={styles.pagination}>{data?.pagination && <Pagination pagination={data?.pagination} />}</div>
          </div>
        </Container>
        {Boolean(data?.articles?.length) && <LNEArticleBlockList data={data?.articles} showFeatured={true} />}
        {Boolean(data?.pagination) && (
          <div className={`${styles['pagination-bottom']} ${styles.pagination}`}>
            <Pagination pagination={data?.pagination} />
          </div>
        )}
        {Boolean(data?.promotionFooter) && <LNEPromotionFooter data={data.promotionFooter} />}
      </main>

      {Boolean(data?.footer) && <Footer data={data.footer} />}
    </div>
  );
};

export default LNECategoryPage;
