import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Form, Offcanvas } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import styles from '../LNEFilters.module.scss';

const LNECategoryPageFilters = () => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });

  // if there are filters in the url, update the checkboxes
  const initialDifficultyString = (router.query.difficulty as string) || '';
  const initialDifficultyArray = initialDifficultyString?.split(',');
  const [isBeginner, setIsBeginner] = useState(initialDifficultyArray.indexOf('BEGINNER') >= 0);
  const [isIntermediate, setIsIntermediate] = useState(initialDifficultyArray.indexOf('INTERMEDIATE') >= 0);
  const [isAdvanced, setIsAdvanced] = useState(initialDifficultyArray.indexOf('ADVANCED') >= 0);

  const handleFilter = (e: any) => {
    const appliedDifficultyString = router?.query?.difficulty as string;
    let noDupes = [...new Set(appliedDifficultyString?.split(','))];
    if (e.target.checked) {
      noDupes.push(e.target.id);
      noDupes = [...new Set(noDupes)];
    } else {
      const removeIndex = noDupes.indexOf(e.target.id);
      if (removeIndex >= 0) {
        noDupes.splice(removeIndex, 1);
      }
    }
    if (Boolean(noDupes.length)) {
      router.query.difficulty = noDupes.join(',');
    } else {
      delete router.query.difficulty;
    }

    // remove pagination params
    delete router?.query?.limit;
    delete router?.query?.offset;

    // push new url with updated params
    router.push({ pathname: router.asPath?.split('?')?.[0], query: router.query }).then();
  };

  // offcanvas for filters
  const [show, setShow] = useState(false);
  const handleCloseFilters = () => setShow(false);
  const handleShowFilters = () => setShow(true);

  return (
    <div data-testid="lne-filters" className={styles['lne-filters']}>
      <Button variant="outline-dark" onClick={handleShowFilters}>
        {formatMessage({ id: 'filter.by', defaultMessage: 'Filter By' })} <i className="bi bi-sliders"></i>
      </Button>

      <Offcanvas show={show} onHide={handleCloseFilters} className={styles['lne-filters-offcanvas']}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{formatMessage({ id: 'filter.by', defaultMessage: 'Filter By' })}:</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Check
            type="checkbox"
            id="BEGINNER"
            label={formatMessage({ id: 'beginner', defaultMessage: 'Beginner' })}
            onChange={(e) => {
              setIsBeginner(e.target.checked);
              handleFilter(e);
            }}
            className={'customFormCheckbox'}
            checked={isBeginner}
          />
          <Form.Check
            type="checkbox"
            id="INTERMEDIATE"
            label={formatMessage({ id: 'intermediate', defaultMessage: 'Intermediate' })}
            onChange={(e) => {
              setIsIntermediate(e.target.checked);
              handleFilter(e);
            }}
            className={'customFormCheckbox'}
            checked={isIntermediate}
          />
          <Form.Check
            type="checkbox"
            id="ADVANCED"
            label={formatMessage({ id: 'advanced', defaultMessage: 'Advanced' })}
            onChange={(e) => {
              setIsAdvanced(e.target.checked);
              handleFilter(e);
            }}
            className={'customFormCheckbox'}
            checked={isAdvanced}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default LNECategoryPageFilters;
