import React, { useEffect } from 'react';
import Head from 'next/head';
import SkipContent from 'components/contentstack/atoms/SkipContent/SkipContent';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './LNEHomepage.module.scss';
import { ILNEHomepageProps } from './types/ILNEHomepageProps';
import ContentBlocks from '../../ContentBlocks';
import Footer from '../../Footer';
import Header from '../../Header';
import PromoBanner from '../../PromoBanner';
import LNECategoryBarAndMenu from '../LNECategoryBarAndMenu/LNECategoryBarAndMenu';
import LNEHeroNav from '../LNEHeroNav';
import LNEPromotionFooter from '../LNEPromotionFooter';

const LNEHomepage = ({ data }: ILNEHomepageProps) => {
  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading) {
      sendGTMEvent({
        event: 'page_view',
        pageName: PAGE_VIEW_EVENTS.LE_HOMEPAGE.NAME,
        pageCategory: PAGE_VIEW_EVENTS.LE_HOMEPAGE.CATEGORY,
        pageType: PAGE_VIEW_EVENTS.LE_HOMEPAGE.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading]);

  return (
    <div data-testid="lne-homepage" className={styles['lne-homepage']}>
      <SkipContent />
      <Head>
        {data?.metaData?.metaTitle && <title>{data?.metaData?.metaTitle}</title>}
        {data?.metaData?.metaDescription && <meta name="description" content={data?.metaData?.metaDescription} />}
        {data?.metaData?.metaKeywords && <meta name="keywords" content={data?.metaData?.metaKeywords} />}
        {data?.heroNav?.navImage?.url && <meta property="og:image" content={data?.heroNav?.navImage?.url} />}
      </Head>

      {Boolean(data?.promoBanner) && <PromoBanner data={data.promoBanner} />}
      {Boolean(data?.header) && <Header data={data.header} />}
      {Boolean(data?.heroNav) && <LNEHeroNav data={data.heroNav} showSearch={true} />}
      <LNECategoryBarAndMenu />

      <main data-testid="lne-homepage-main-content" id="nkn-main-content" tabIndex={-1}>
        <ContentBlocks
          data={data.lneHomepageContentBlocks}
          ideasAndInspirationCount={data?.ideasAndInspirationCount}
          productsAndInnovationCount={data?.productsAndInnovationCount}
          tipsAndTechniquesCount={data?.tipsAndTechniquesCount}
        />
        {Boolean(data?.promotionFooter) && <LNEPromotionFooter data={data.promotionFooter} />}
      </main>

      {Boolean(data?.footer) && <Footer data={data.footer} />}
    </div>
  );
};

export default LNEHomepage;
