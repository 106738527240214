const ReviewSummary = () => {
  return (
    <div id="tt-reviews" data-testid="ratingsAndReviews-summary">
      <div id="tt-reviews-summary"></div>
      <div id="tt-reviews-list"></div>
    </div>
  );
};

export default ReviewSummary;
