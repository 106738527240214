import React, { useCallback } from 'react';
import router from 'next/router';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import styles from './LNESearchForm.module.scss';
import { ILNESearchFormProps } from './types/ILNESearchFormProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { tablet } from '../../../../helpers/utils/screensizes';

const LNESearchForm = ({ placement }: ILNESearchFormProps) => {
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });
  const { register, handleSubmit, setValue } = useForm({ mode: 'onSubmit' });

  const [isTabletOrBigger] = useMediaQuery(tablet);
  const placeholderId = isTabletOrBigger ? 'lne.search.placeholder' : 'search-articles';

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.name, e.target.value);
    },
    [setValue],
  );

  const onSubmit = (fields: FieldValues) => {
    const searchTerm = String(fields['lne-search']?.trim());
    if (Boolean(searchTerm)) {
      router.push(`/learn-and-explore/s/${searchTerm}`).then();
    }
  };

  return (
    <div className={styles['lne-search-form']}>
      <Form
        className={`${styles.search} ${placement === 'hero' ? styles.hero : styles.menu}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputGroup>
          <Form.Control
            type="search"
            placeholder={formatMessage({
              id: placeholderId,
              defaultMessage: 'What do you want to learn about today?',
            })}
            aria-label={formatMessage({
              id: 'search-placeholder',
              defaultMessage: 'Search',
            })}
            {...register('lne-search', { required: true })}
            onChange={handleChange}
          />
          <Button type={'submit'} id="lne-search-submit" className={`btn-yellow`}>
            <MagnifyingGlassIcon className="h-24 w-24 stroke-secondary-black" />
          </Button>
        </InputGroup>
      </Form>
    </div>
  );
};

export default LNESearchForm;
