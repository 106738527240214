import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import SkipContent from 'components/contentstack/atoms/SkipContent/SkipContent';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './LNESearchResultsPage.module.scss';
import LNESearchResultsPageFilters from './LNESearchResultsPageFilters';
import { ILNESearchResultsPageProps } from './types/ILNESearchResultsPageProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import Pagination from '../../../nikon-ui/atoms/pagination';
import Footer from '../../Footer';
import Header from '../../Header';
import PromoBanner from '../../PromoBanner';
import LNEArticleBlockList from '../LNEArticleBlockList';
import LNECategoryBarAndMenu from '../LNECategoryBarAndMenu/LNECategoryBarAndMenu';
import LNEHeroNav from '../LNEHeroNav';
import LNEPromotionFooter from '../LNEPromotionFooter';

const LNESearchResultsPage = ({ data }: ILNESearchResultsPageProps) => {
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });
  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading) {
      sendGTMEvent({
        event: 'page_view',
        pageName: PAGE_VIEW_EVENTS.LE_SEARCH.NAME,
        pageCategory: PAGE_VIEW_EVENTS.LE_SEARCH.CATEGORY,
        pageType: PAGE_VIEW_EVENTS.LE_SEARCH.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading]);

  return (
    <div data-testid="lne-collection-page" className={styles['lne-collection-page']}>
      <SkipContent />
      {Boolean(data?.promoBanner) && <PromoBanner data={data.promoBanner} />}
      {Boolean(data?.header) && <Header data={data.header} />}
      {Boolean(data?.heroNav) && <LNEHeroNav data={data.heroNav} showSearch={false} />}
      <LNECategoryBarAndMenu />

      <main
        data-testid="lne-collection-page-main-content"
        className={styles['lne-collection-page-main-content']}
        id="nkn-main-content"
        tabIndex={-1}
      >
        <Container>
          <h1>
            <span>{formatMessage({ id: 'results.for', defaultMessage: 'Results for:' })}</span>
            {Boolean(data?.author) ? data?.author : data?.searchTerm}
          </h1>
          <div className={styles['lne-filter-pagination-container']}>
            <LNESearchResultsPageFilters />
            <div className={styles.pagination}>{data?.pagination && <Pagination pagination={data?.pagination} />}</div>
          </div>
        </Container>
        {Boolean(data?.articles?.length) ? (
          <LNEArticleBlockList data={data?.articles} showFeatured={false} />
        ) : (
          <Container className={styles['no-results']}>
            <h2>{formatMessage({ id: 'no.results', defaultMessage: 'No Results' })}</h2>
          </Container>
        )}
        {Boolean(data?.pagination) && (
          <div className={`${styles['pagination-bottom']} ${styles.pagination}`}>
            <Pagination pagination={data?.pagination} />
          </div>
        )}
        {Boolean(data?.promotionFooter) && <LNEPromotionFooter data={data.promotionFooter} />}
      </main>

      {Boolean(data?.footer) && <Footer data={data.footer} />}
    </div>
  );
};

export default LNESearchResultsPage;
