import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Col, Popover } from 'react-bootstrap';
import Button from 'components/commercetools-ui/atoms/button';
import { UIProduct } from 'components/commercetools-ui/organisms/product/ProductDetails/types/UIProduct';
import { useAddToCartOverlay } from 'context/add-to-cart-overlay';
import { ImageHelpers } from 'helpers/imageHelpers';
import { toUIColor } from 'helpers/mappers/toUIColor';
import { toUIProduct } from 'helpers/mappers/toUIProduct';
import { toUISize } from 'helpers/mappers/toUIsize';
import { useCart } from 'frontastic';
import styles from './ProductItem.module.scss';
import Tooltip from './Tooltip';
import { IProductItemProps } from './types/IProductItemProps';
import { appLocales } from '../../../../helpers/constants/appLocales';
import { IMAGE_SOURCE } from '../../../../helpers/constants/imageSources';
import { CurrencyHelpers } from '../../../../helpers/currencyHelpers';
import { useData } from '../../../../helpers/hooks/useData';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { getVariantAddToCartLimit, getVariantCartQuantity } from '../../../../helpers/productHelpers';
import { getLocalizationInfo } from '../../../../project.config';
import { TLocalizationInfo } from '../../../../types/TLocalizationInfo';
import { EAddToCartStatus, TNikonProduct, TNikonVariant } from '../../../../types/TNikonProduct';
import { buttonTextConfig } from '../../../commercetools-ui/organisms/product/ProductDetails/components/AddToCart/types/buttonTextConfig';

const backorderTooltip = (
  <Popover>
    Other buying options may be available at this time. Backorders will not be charged until inventory becomes
    available. We will notify you when it is in stock. We do not accept PayPal as a method of payment for any
    backordered items.
  </Popover>
);

const preorderTooltip = (
  <Popover>
    This product is available for preorder. When stock is available and ready to ship you will be alerted. We do not
    accept PayPal as a method of payment for any preordered items.
  </Popover>
);

const ProductItem = ({ product }: IProductItemProps) => {
  const { locale: routerLocale, defaultLocale } = useRouter();
  const localizationInfo: TLocalizationInfo = getLocalizationInfo(routerLocale || defaultLocale);
  const isUsaStore = localizationInfo.appLocale === appLocales.EN_US;
  const isCanadaStore =
    localizationInfo.appLocale === appLocales.EN_CA || localizationInfo.appLocale === appLocales.FR_CA;
  const { formatMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [variant, isValidVariant] = useData<TNikonProduct, TNikonVariant>(product, { return: 'variants[0]' });

  const { addItem, data: cartData } = useCart();
  const [quantity, setQuantity] = useState<number>(1);
  const [mappedProduct, setMappedProduct] = useState<UIProduct>();
  const [added, setAdded] = useState(false);
  const [isShowAddToCartError, setIsShowAddToCartError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { show } = useAddToCartOverlay();

  useEffect(() => {
    if (product && variant) {
      const colors = toUIColor(product);
      const sizes = toUISize(product);
      const productToUse = toUIProduct(product, variant, colors, sizes);
      setMappedProduct({ ...productToUse, images: [productToUse.images[0]] });
    }
  }, [product?.slug, variant?.id]);

  const handleOnAddToCart = async () => {
    setIsShowAddToCartError(false);
    setLoading(true);

    const cartResponse = await addItem(variant, quantity);

    setLoading(false);
    setIsShowAddToCartError(!cartResponse.cartId);

    if (cartResponse.cartId) {
      setAdded(true);
      setTimeout(() => {
        setAdded(false);
        setQuantity(1);
      }, 5000);

      show(mappedProduct as unknown as TNikonProduct, variant, undefined, quantity);
    }
  };

  const isAvailableForSale = variant.attributes?.['isAvailableForSale'];

  if (!isValidVariant || !isAvailableForSale) {
    return <></>;
  }

  const addToCartStatus: EAddToCartStatus = variant.addToCartStatus || EAddToCartStatus.ADD_TO_CART;
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(routerLocale);
  const image = variant.representativeImage || variant.images?.[0] || imageUnavailable;

  const isArchived = Boolean(product.isArchived);

  let availabilityMessage = '';
  const buttonTextConfiguration = buttonTextConfig[addToCartStatus];
  let buttonText = formatMessage(buttonTextConfiguration);
  let isBackorder = false;
  let isPreorder = false;
  let isOutOfStock = false;

  if ((addToCartStatus as EAddToCartStatus) === EAddToCartStatus.PREORDER) {
    availabilityMessage =
      variant.attributes?.altPreorderMessaging ||
      formatMessage({
        id: 'preOrderMessage',
        defaultMessage: 'This product can be preordered.',
      });
    isPreorder = true;
  }

  if ((addToCartStatus as EAddToCartStatus) === EAddToCartStatus.BACKORDER) {
    availabilityMessage =
      variant.attributes?.altBackorderMessaging ||
      formatMessage({
        id: 'backOrderMessage',
        defaultMessage: 'This product is backordered. Order now and we will ship when stock becomes available.',
      });
    isBackorder = true;
  }

  if ((addToCartStatus as EAddToCartStatus) === EAddToCartStatus.OUT_OF_STOCK) {
    isOutOfStock = true;
  }

  if (added) {
    buttonText = formatMessage({ id: 'productItem.itemAdded', defaultMessage: 'Item Added' });
  }

  const variantCartQuantity = getVariantCartQuantity({ cart: cartData, sku: variant.sku });
  const variantAddToCartLimit = getVariantAddToCartLimit(variant, localizationInfo.appLocale);
  const userCanAddMoreUnitsToCart = variantCartQuantity < variantAddToCartLimit;
  const canAdd = !added && !isOutOfStock && !loading;
  const btnClass = canAdd ? 'yellow' : added ? 'green' : 'disabled';

  const overviewPage = `${product._url}/overview`;

  return (
    <Col xl={2} md={3} as="li" className="">
      <div data-testid="container" className={styles['container']}>
        <div className={styles['product-image']}>
          <a href={overviewPage}>
            <div className={styles['image-container']}>
              <img
                data-testid="image"
                src={ImageHelpers.modifiedCTImageUrl(image, 'small')}
                loading="lazy"
                className={styles['img']}
                onError={({ currentTarget }) => {
                  currentTarget.src = imageUnavailable;
                }}
                alt={product.name}
              />
            </div>
          </a>
        </div>
        <div className={styles['content']}>
          <div className={styles['description']}>
            <a href={overviewPage} className={styles['title']}>
              <span data-testid="title">{product.name}</span>
            </a>

            {(isUsaStore || isCanadaStore) && (
              <div data-testid="price" className={styles['price']}>
                {isArchived && formatMessage({ id: 'productItem.archived', defaultMessage: 'Archived' })}

                {!isArchived && !variant.discountedPrice?.centAmount && variant.price?.centAmount && (
                  <span data-testid="regular-price">
                    {CurrencyHelpers.formatForCurrency(variant.price, routerLocale)}
                  </span>
                )}

                {!isArchived && variant.discountedPrice?.centAmount && (
                  <div className="flex flex-row justify-between">
                    <div className="flex items-end gap-8">
                      <span className={styles.price} data-testid="discount-price">
                        {CurrencyHelpers.formatForCurrency(variant.discountedPrice, routerLocale)}
                      </span>
                      <span className={styles['oldPrice']} data-testid="old-price">
                        {CurrencyHelpers.formatForCurrency(variant.price, routerLocale)}
                      </span>
                    </div>
                  </div>
                )}

                {!isArchived && variant.discountedPrice && (
                  <span data-testid="you-save-message" className={styles['save-money']}>
                    {formatCartMessage({ id: 'total.discount.title', defaultMessage: 'You Save' })}{' '}
                    {CurrencyHelpers.formatForCurrency(
                      CurrencyHelpers.subtractCurrency(variant.price, variant.discountedPrice),
                      routerLocale,
                    )}
                  </span>
                )}
              </div>
            )}
          </div>

          {!isOutOfStock && !userCanAddMoreUnitsToCart && (
            <div className={styles['max-quantity-meet']}>
              {formatMessage({ id: 'maxQuantityMeet', defaultMessage: 'Maximum order quantity has been met.' })}
            </div>
          )}

          {!isArchived && isUsaStore && (
            <div className={styles['button-container']}>
              <Button
                data-testid="button"
                className={`btn-${btnClass} ${styles['product-cta']}`}
                variant="primary"
                loading={loading}
                disabled={isOutOfStock || !userCanAddMoreUnitsToCart}
                onClick={(canAdd && handleOnAddToCart) || undefined}
              >
                {buttonText}
              </Button>
            </div>
          )}

          {isShowAddToCartError && (
            <p className={styles['add-to-cart-error']}>
              {formatCartMessage({
                id: 'genericAddToCartError',
                defaultMessage: 'We are unable to add to cart. Please try again.',
              })}
            </p>
          )}

          {isUsaStore && (isBackorder || isPreorder) && (
            <div style={{ paddingTop: isArchived ? '25px' : '0' }}>
              <Tooltip
                availabilityMessage={availabilityMessage}
                overlay={isPreorder ? preorderTooltip : backorderTooltip}
              />
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};

export default ProductItem;
