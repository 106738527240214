import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './LNEArticlePage.module.scss';
import { ILNEArticleHeaderBlockProps } from './types/ILNEArticleHeaderBlockProps';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { getLNEArticleBylineText } from '../utils';

const LNEArticleHeaderBlock = ({
  articleFieldsGroup,
  authors,
  creators,
  metadataFields,
}: ILNEArticleHeaderBlockProps) => {
  const router = useRouter();
  const pageType = router.asPath.includes('/learn-and-explore/')
    ? 'LE'
    : router.asPath.includes('press-room')
    ? 'Press'
    : 'LE';
  const { formatMessage } = useFormat({ name: 'learn-and-explore' });

  const byline = getLNEArticleBylineText(authors, creators);
  const title = Boolean(articleFieldsGroup?.articleDisplayName)
    ? articleFieldsGroup?.articleDisplayName
    : articleFieldsGroup?.articleTitle;

  return (
    <div data-testid="lne-article-header-block" className={styles['lne-article-header-block']}>
      <Head>
        <title>{title}</title>
        <meta className="swiftype" name="niBoostType" data-type="float" content="1.2" />
        <meta className="swiftype" name="niDefaultTitle" data-type="string" content={title} />

        {Boolean(articleFieldsGroup.dateSelectors && articleFieldsGroup.dateSelectors.dateWritten) && (
          <meta
            className="swiftype"
            name="published_at"
            data-type="date"
            content={articleFieldsGroup?.dateSelectors?.dateWritten?.toString()}
          />
        )}

        {Boolean(articleFieldsGroup?.images?.representativeImage?.url) && (
          <meta
            className="swifttype"
            name="image"
            data-type="enum"
            content={articleFieldsGroup?.images?.representativeImage?.url}
          />
        )}

        {Boolean(metadataFields?.metaDescription) && (
          <>
            <meta className="swiftype" name="description" data-type="text" content={metadataFields?.metaDescription} />
            <meta property="og:description" content={metadataFields?.metaDescription} />
            <meta className="swiftype" name="sections" data-type="string" content={metadataFields?.metaDescription} />
          </>
        )}

        <meta className="swiftype" name="type" data-type="enum" content={pageType} />
        {Boolean(global.window) && <link rel="canonical" href={global.window.location.href} />}
      </Head>
      {Boolean(articleFieldsGroup?.images?.representativeImage?.url) && (
        <img className="flipboard-image hidden" src={articleFieldsGroup?.images?.representativeImage?.url} />
      )}
      {Boolean(articleFieldsGroup?.articleSummary) && (
        <div className="flipboard-keep hidden">{articleFieldsGroup?.articleSummary}</div>
      )}

      <Container className={styles['lne-article-top']}>
        {Boolean(
          articleFieldsGroup?.articleClassification &&
            articleFieldsGroup?.articleClassification?.toLowerCase() !== 'none',
        ) && (
          <div className={`${styles.difficulty} ${styles[articleFieldsGroup.articleClassification.toLowerCase()]}`}>
            {formatMessage({
              id: articleFieldsGroup?.articleClassification?.toLowerCase() || '',
              defaultMessage: articleFieldsGroup?.articleClassification,
            })}
          </div>
        )}
        <Row className={styles.title}>
          <Col xs={12}>
            <h1 className="flipboard-title" itemProp="name">
              {title}
            </h1>

            {Boolean(articleFieldsGroup?.articleSubTitle) && (
              <p className={styles.subtitle}>{articleFieldsGroup?.articleSubTitle}</p>
            )}

            {Boolean(authors?.length || creators?.length) && (
              <div className={styles.contributors}>
                {Boolean(byline.authorByline !== '' && byline.authorString !== '') && (
                  <div className={styles.byline}>
                    <em>
                      {formatMessage({
                        id: byline.authorByline,
                        defaultMessage: 'By',
                      })}
                    </em>
                    <div dangerouslySetInnerHTML={{ __html: byline.authorString }}></div>
                  </div>
                )}
                {Boolean(byline.creatorByline !== '' && byline.creatorString !== '') && (
                  <div className={styles.byline}>
                    <em>
                      {formatMessage({
                        id: byline.creatorByline,
                        defaultMessage: 'Featuring',
                      })}
                    </em>
                    <div dangerouslySetInnerHTML={{ __html: byline.creatorString }}></div>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>

      {Boolean(metadataFields?.metaKeyword || articleFieldsGroup?.keywords) && (
        <meta itemProp="keywords" content={metadataFields?.metaKeyword || articleFieldsGroup?.keywords} />
      )}
      {Boolean(metadataFields?.metaDescription) && (
        <meta itemProp="description" content={metadataFields?.metaDescription} />
      )}
    </div>
  );
};

export default LNEArticleHeaderBlock;
